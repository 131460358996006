import { useEffect, useState } from "react";
import styles from "./CarWash.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";
import { useLocation } from "react-router-dom";

export default function CarWash() {
  const location = useLocation();
  const bookingId = new URLSearchParams(location.search).get("bookingId");
  const [booking, setBooking] = useState();
  const [address, setAddress] = useState();
  const [workers, setWorkers] = useState([]);
  const [workerId, setWorkerId] = useState();
  const [remarks, setRemarks] = useState();
  const [startedAt, setStartedAt] = useState();
  const [hasError, setHasError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [estimatedCost, setEstimatedCost] = useState();

  const fetchInitialData = () => {
    const getCarWashBooking = api.get("/api/bookings/carWash/" + bookingId);
    const getAllWorkers = api.get("/api/workers/");

    axios.all([getCarWashBooking, getAllWorkers]).then(
      axios.spread((...allData) => {
        setBooking(allData[0].data);
        setWorkers(allData[1].data);
        setWorkerId(allData[1].data[0].id);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const validateForm = () => {
    if (startedAt === "" && address === "" && workerId === "") return false;

    return true;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setAddress("");
    setWorkerId("");
    setRemarks("");
    setStartedAt("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      // let scheduledAt = new Date(scheduleDate + ' ' + scheduleTime);

      const carWash = {
        bookingId,
        workerId,
        remarks,
        startedAt,
        address,
        estimatedCost,
      };

      api
        .post("/api/carWash/", carWash)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Car Wash</header>
      <form onSubmit={handleSubmit}>
        <div>
          {booking && (
            <div className={styles.bookingDeailContainer}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Booking #:</div>
                <div className={styles.bookingValue}>
                  {booking.bookingNumber}{" "}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Name:</div>
                <div className={styles.bookingValue}>
                  {booking.customerFirstName} {booking.customerLastName}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Model:</div>
                <div className={styles.bookingValue}>
                  {booking.brand} {booking.carModel}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Car Wash Date:</div>
                <div className={styles.bookingValue}>{booking.scheduledAt}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Estimated Price:</div>
                <div className={styles.bookingValue}>
                  <input
                    type="number"
                    defaultValue={booking.estimatedPrice}
                    onChange={(e) => setEstimatedCost(e.target.value)}
                  />
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Address:</div>
                <div className={styles.bookingValue}>
                  <textarea
                    rows={2}
                    onChange={(e) => setAddress(e.target.value)}
                    defaultValue={booking.street}
                  />
                </div>
              </div>

              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Worker:</div>
                <div className={styles.bookingValue}>
                  <select
                    className={styles.workerddl}
                    onChange={(e) => setWorkerId(e.target.value)}
                  >
                    <option> Select Worker </option>
                    {workers.map((worker) => (
                      <option key={worker.id} value={worker.id}>
                        {worker.name}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Start At:</div>
                <div className={styles.bookingValue}>
                  {" "}
                  <div className={styles.scheduleDate}>
                    <label for="scheduleAt"></label>
                    <input
                      type="datetime-local"
                      onChange={(e) => setStartedAt(e.target.value)}
                      id="scheduleAt"
                      name="scheduleAt"
                    />
                  </div>
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Remarks:</div>
                <div className={styles.bookingValue}>
                  <TextField
                    multiline
                    rows={2}
                    onChange={(e) => setRemarks(e.target.value)}
                    label="Enter Remarks"
                  />
                </div>
              </div>
              <Button
                className={styles.saveButton}
                type="submit"
                variant="contained"
                startIcon={<SaveIcon />}
              >
                {" "}
                Create Car Wash
              </Button>
              <br />
              {hasError && (
                <p className={mainStyles.error}>
                  Please fill all the mandatory fields.
                </p>
              )}
              {isSaved && (
                <p className={mainStyles.success}>
                  {" "}
                  Car Wash created Successfully
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
