import { useEffect, useState } from "react";
import styles from "./booking.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import { api } from "../../config/apiConfig";
import { useLocation } from "react-router-dom";
import mainStyles from "../Main.module.css";

export default function BikeServiceBookingDetail() {
  const [booking, setBooking] = useState();
  const location = useLocation();
  const bookingId = new URLSearchParams(location.search).get("bookingId");
  useEffect(() => {
    api.get("/api/bookings/bikeService/" + bookingId).then((res) => {
      setBooking(res.data);
    });
  }, [bookingId]);

  return (
    <div>
      <header className={mainStyles.header}>Bike Service Booking Detail</header>
      {booking && (
        <div className={styles.bookingDeailContainer}>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Booking #:</div>
            <div className={styles.bookingValue}>{booking.bookingNumber} </div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Customer Name:</div>
            <div className={styles.bookingValue}>
              {booking.firstName} {booking.lastName}
            </div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Model:</div>
            <div className={styles.bookingValue}>
              {booking.brand} {booking.model}
            </div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Reg. Number:</div>
            <div className={styles.bookingValue}>
              {booking.registrationNumber}
            </div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Status:</div>
            <div className={styles.bookingValue}>{booking.status}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Service At:</div>
            <div className={styles.bookingValue}>{booking.scheduledAt}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Picked Up At:</div>
            <div className={styles.bookingValue}>{booking.pickedUpAt}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Dropped at SC:</div>
            <div className={styles.bookingValue}>{booking.droppedAt}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Completed At:</div>
            <div className={styles.bookingValue}>{booking.completedAt}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Cancelled At:</div>
            <div className={styles.bookingValue}>{booking.cancelledAt}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Email:</div>
            <div className={styles.bookingValue}>{booking.email}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Address:</div>
            <div className={styles.bookingValue}>{booking.address}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Color:</div>
            <div className={styles.bookingValue}>{booking.color}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Mobile:</div>
            <div className={styles.bookingValue}>{booking.phoneNo}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Workshop:</div>
            <div className={styles.bookingValue}>{booking.workshopName}</div>
          </div>
          <div className={styles.bookingRow}>
            <div className={styles.bookingKey}>Remarks:</div>
            <div className={styles.bookingValue}>{booking.remarks}</div>
          </div>
        </div>
      )}
    </div>
  );
}
