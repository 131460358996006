import React, { useState } from "react";
import styles from "./Review.module.css";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import StarSharp from "@mui/icons-material/StarSharp";
import LoadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function ModerateReview() {
  const [review, setReview] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [isSuccess, setIsSuccess] = useState(false);
  const [isError, setIsError] = useState(false);
  const [showBikeServingReviewForm, setShowBikeServicingReviewForm] =
    useState(false);
  const [showCarWashReviewForm, setShowCarWashReviewForm] = useState(false);
  const [showWorkshopReviewForm, setShowWorkshopReviewForm] = useState(false);

  const setterMap = {};
  setterMap["bikeServicing"] = setShowBikeServicingReviewForm;
  setterMap["carWash"] = setShowCarWashReviewForm;
  setterMap["workshop"] = setShowWorkshopReviewForm;

  const entities = [
    {
      id: "bikeServicing",
      name: "Bike Servicing",
    },
    {
      id: "carWash",
      name: "Car Wash",
    },
    {
      id: "workshop",
      name: "Workshop",
    },
  ];

  const showPage = (entity) => {
    setterMap["bikeServicing"](false);
    setterMap["carWash"](false);
    setterMap["workshop"](false);
    setterMap[entity](true);
  };

  const handleApprove = (entity, reviewId) => {
    setIsLoading(true);
    api
      .put("/api/reviews/" + entity + "/approve/" + reviewId)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setIsSuccess(true);
          setIsError(false);
        } else {
          setIsError(true);
          setIsSuccess(false);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  const handleReject = (entity, reviewId) => {
    setIsLoading(true);
    api
      .put("/api/reviews/" + entity + "/reject/" + reviewId)
      .then((res) => {
        setIsLoading(false);
        if (res.data) {
          setIsSuccess(true);
          setIsError(false);
        } else {
          setIsError(true);
          setIsSuccess(false);
        }
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  const loadReviews = (entity) => {
    showPage(entity);
    setIsLoading(true);
    api
      .get("/api/reviews/" + entity + "/pending")
      .then((res) => {
        setReview(res.data);
        setIsLoading(false);
      })
      .catch((error) => {
        setIsError(true);
      });
  };

  return isLoading ? (
    <div className={styles.loadingContainer}>
      <img src={LoadingImage} alt="loading" />
    </div>
  ) : (
    <div className={styles.container}>
      <div className={styles.heading}>
        <div className={styles.heading1}>Moderate Review</div>
      </div>

      <div className={styles.ddlContainer}>
        <Box sx={{ minWidth: 120 }}>
          <FormControl fullWidth>
            <InputLabel id="entitySelect">Entity</InputLabel>
            <Select
              labelId="entitySelect"
              id="entitySelect"
              label="Entity"
              onChange={(e) => loadReviews(e.target.value)}
              className={styles.ddl}
              defaultValue=""
            >
              {entities.map((entity) => (
                <MenuItem key={entity.id} value={entity.id}>
                  {entity.name}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Box>
      </div>

      {showBikeServingReviewForm && (
        <div>
          <div className={styles.reviewContainer}>
            {review &&
              review.map((review) => (
                <div key={review.id} className={styles.review}>
                  <div className={styles.customer}>{review.customer}</div>
                  <div className={styles.reviewText}>
                    {review.serviceReviewText}
                  </div>
                  <div className={styles.starContainer}>
                    {[...Array(review.serviceRating)].map((item, i) => (
                      <div className={styles.starReview}>
                        <StarSharp sx={{ fontSize: 45 }} />
                      </div>
                    ))}
                  </div>
                  <div className={styles.worker}>{review.worker}</div>
                  <div className={styles.btnContainer}>
                    <button
                      className={styles.approveBtn}
                      onClick={handleApprove.bind(
                        this,
                        "bikeServicing",
                        review.serviceReviewId
                      )}
                    >
                      Approve
                    </button>
                    <button
                      className={styles.rejectBtn}
                      onClick={handleReject.bind(
                        this,
                        "bikeServicing",
                        review.serviceReviewId
                      )}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {showCarWashReviewForm && (
        <div>
          <div className={styles.reviewContainer}>
            {review &&
              review.map((review) => (
                <div key={review.id} className={styles.review}>
                  <div className={styles.customer}>{review.customer}</div>
                  <div className={styles.reviewText}>
                    {review.carWashReviewText}
                  </div>
                  <div className={styles.starContainer}>
                    {[...Array(review.carWashRating)].map((item, i) => (
                      <div className={styles.starReview}>
                        <StarSharp sx={{ fontSize: 45 }} />
                      </div>
                    ))}
                  </div>
                  <div className={styles.worker}>{review.worker}</div>
                  <div className={styles.btnContainer}>
                    <button
                      className={styles.approveBtn}
                      onClick={handleApprove.bind(
                        this,
                        "carWash",
                        review.carWashReviewId
                      )}
                    >
                      Approve
                    </button>
                    <button
                      className={styles.rejectBtn}
                      onClick={handleReject.bind(
                        this,
                        "carWash",
                        review.carWashReviewId
                      )}
                    >
                      Reject
                    </button>
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
      {showWorkshopReviewForm && (
        <div>
          <div className={styles.reviewContainer}>
            {review &&
              review.map((review) => (
                <div key={review.id} className={styles.review}>
                  <div className={styles.customer}>{review.customer}</div>
                  <div className={styles.reviewText}>
                    {review.workshopReviewText}
                  </div>
                  <div className={styles.starContainer}>
                    {[...Array(review.workshopRating)].map((item, i) => (
                      <div className={styles.starReview}>
                        <StarSharp sx={{ fontSize: 45 }} />
                      </div>
                    ))}
                  </div>
                  <div className={styles.worker}>{review.workshop}</div>
                  <div className={styles.btnContainer}>
                    <button
                      className={styles.approveBtn}
                      onClick={handleApprove.bind(
                        this,
                        "workshop",
                        review.workshopReviewId
                      )}
                    >
                      Approve
                    </button>
                    <button
                      className={styles.rejectBtn}
                      onClick={handleReject.bind(
                        this,
                        "workshop",
                        review.workshopReviewId
                      )}
                    >
                      Reject
                    </button>
                  </div>
                  <div className={mainStyles.success}>
                    {" "}
                    {isSuccess && <div>Review Approved Successfully</div>}
                  </div>
                  <div className={mainStyles.error}>
                    {" "}
                    {isError && <div>Review Rejected Successfully</div>}
                  </div>
                </div>
              ))}
          </div>
        </div>
      )}
    </div>
  );
}
