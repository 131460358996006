import { useEffect, useState } from "react";
import styles from "./State.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { API_HOST_URL } from "../../config/apiConfig";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";

export default function State() {
  const [stateName, setStateName] = useState("");
  const [stateDescription, setStateDescription] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [countries, setCountries] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [countryId, setCountryId] = useState("");

  const headerList = {
    "Content-Type": "application/json",
  };

  useEffect(() => {
    fetch(API_HOST_URL + "/countries/", {
      headers: headerList,
    })
      .then((response) => {
        return response.json();
      })
      .then((res) => {
        setIsLoading(false);
        setCountries(res);
      });
  }, []);

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const validateForm = () => {
    if (stateName === "" || countryId === "") {
      return false;
    }
    return true;
  };

  const resetForm = () => {
    setStateName("");
    setStateDescription("");
    setCountryId("");
  };

  const handleSubmit = (e) => {
    if (validateForm()) {
      e.preventDefault();
      setIsLoading(true);
      const state = { stateName, stateDescription, countryId };

      fetch(API_HOST_URL + "/states/", {
        method: "POST",
        headers: headerList,
        body: JSON.stringify(state),
      })
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingDiv}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div className={styles.mainDiv}>
      <header className={mainStyles.header}>Add State</header>
      <form onSubmit={handleSubmit}>
        <div className={styles.ddlContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => setCountryId(e.target.value)}
          >
            <option> Select Country </option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setStateName(e.target.value)}
            label="Enter State Name"
          />
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            onChange={(e) => setStateDescription(e.target.value)}
            label="Enter State Description"
            multiline
            rows={3}
          />
        </div>
        <div className={styles.componentContainer}>
          <Button
            className={styles.saveBtn}
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {" "}
            Save State
          </Button>
        </div>
        <div className={styles.componentContainer}>
          {isSaved && (
            <div>
              <p className={mainStyles.success}>State Saved Successfully</p>
            </div>
          )}
          {hasError && (
            <div>
              <p className={mainStyles.error}>
                Some error occurred, please try later.
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
