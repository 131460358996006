import axios from "axios";

import {
  GET_USER_FAILURE,
  GET_USER_REQUEST,
  GET_USER_SUCCESS,
  LOGIN_FAILURE,
  LOGIN_REQUEST,
  LOGIN_SUCCESS,
  LOGOUT_FAILURE,
  LOGOUT_REQUEST,
  LOGOUT_SUCCESS,
  REGISTER_FAILURE,
  REGISTER_REQUEST,
  REGISTER_SUCCESS,
} from "./ActionType";
import { API_HOST_URL } from "../../../config/apiConfig";

const token = localStorage.getItem("jwt");

const registerRequest = () => ({ type: REGISTER_REQUEST });
const registerSuccess = (user) => ({ type: REGISTER_SUCCESS, payload: user });
const registerFailure = (error) => ({
  type: REGISTER_FAILURE,
  payload: error,
});

export const register = (userData) => async (dispatch) => {
  dispatch(registerRequest());
  try {
    const response = await axios.post(`${API_HOST_URL}/auth/signup`, userData);
    const user = response.data;
    console.log("user", user);
    // if (user.jwt) {
    //   localStorage.setItem("jwt", user.jwt);
    // }
    dispatch(registerSuccess(user));
  } catch (error) {
    dispatch(registerFailure(error.message));
  }
};

const loginRequest = () => ({ type: LOGIN_REQUEST });
const loginSuccess = (user) => ({ type: LOGIN_SUCCESS, payload: user });
const loginFailure = (error) => ({
  type: LOGIN_FAILURE,
  payload: error,
});

export const login = (request) => async (dispatch) => {
  dispatch(loginRequest());
  try {
    const response = await axios.post(
      `${API_HOST_URL}/auth/login`,
      request.userData
    );
    const user = response.data;
    console.log("user", user);
    if (user.jwt) {
      localStorage.setItem("jwt", user.jwt);
      localStorage.setItem("userId", user.userId);
      localStorage.setItem("role", user.role);
      request.navigate("/home");
      window.location.reload(); // to refresh the page
    }
    dispatch(loginSuccess(user.jwt));
  } catch (error) {
    dispatch(loginFailure(error.response.data));
  }
};

const getUserRequest = () => ({ type: GET_USER_REQUEST });
const getUserSuccess = (user) => ({ type: GET_USER_SUCCESS, payload: user });
const getUserFailure = (error) => ({
  type: GET_USER_FAILURE,
  payload: error,
});

export const getUser = (jwt, navigate) => async (dispatch) => {
  dispatch(getUserRequest());
  try {
    const response = await axios.get(`${API_HOST_URL}/api/profile`, {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    });
    const user = response.data;
    console.log("user", user);

    dispatch(getUserSuccess(user));
  } catch (error) {
    localStorage.clear();
    navigate("/");
    dispatch(getUserFailure(error.message));
  }
};

const logoutRequest = () => ({ type: LOGOUT_REQUEST });
const logoutSuccess = () => ({ type: LOGOUT_SUCCESS });
const logoutFailure = (error) => ({ type: LOGOUT_FAILURE, payload: error });

export const logout = (navigate) => (dispatch) => {
  try {
    dispatch(logoutRequest());
    localStorage.clear();
    navigate("/");
    dispatch(logoutSuccess());
  } catch (error) {
    dispatch(logoutFailure(error.message));
  }
};
