import React from "react";
import { useState } from "react";
import { API_HOST_URL } from "../Constant/Constant";
import styles from "./Review.module.css";
import lodingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function AggregateWorkshopRating() {
  const [isLoading, setIsLoading] = useState(false);
  const [ratings, setRatings] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const handleClick = () => {
    setIsLoading(true);
    api
      .put("/api/workshops/updateRatings")
      .then((res) => {
        setRatings(res.data);
        onSuccessfulSave();
      })
      .catch((err) => {
        onFailedSave();
      });
  };

  return isLoading ? (
    <img src={lodingImage} alt="loading" />
  ) : (
    <div>
      <h1 className={mainStyles.header}>Aggregate Workshop Rating</h1>
      <div className={styles.btnContainer}>
        <button className={styles.aggregateBtn} onClick={handleClick}>
          Aggregate Rating
        </button>
      </div>
      <div className={styles.workshopRating}>
        {isLoading && <img src={lodingImage} alt="loading" />}
        <div>
          {ratings &&
            ratings.map((rating) => {
              return (
                <div key={rating.workshopId}>
                  <div className={styles.ratingContainer}>
                    <div className={styles.name}>{rating.workshop}</div>
                    <div className={styles.rating}>{rating.rating}</div>
                  </div>
                </div>
              );
            })}
        </div>
      </div>
      <div className={styles.successContainer}>
        {isSaved && (
          <div className={styles.success}>Rating Updated Successfully</div>
        )}
        {hasError && (
          <div className={styles.error}>Error while updating rating</div>
        )}
      </div>
    </div>
  );
}
