import React, { useEffect, useState } from "react";
import { api } from "../../config/apiConfig";
import mainStyles from "../Main.module.css";
import styles from "./Profile.module.css";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const Profile = () => {
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  useEffect(() => {
    api.get("/api/profile").then((res) => {
      setProfile(res.data);
    });
  }, []);

  const role = localStorage.getItem("role");

  return (
    <div>
      <div className={mainStyles.header}>Profile Details</div>
      {profile && (
        <div className={styles.fieldContainer}>
          <div className={mainStyles.fieldName}>Name</div>
          <div className={mainStyles.fieldValue}>: {profile.name}</div>
          <div className={mainStyles.fieldName}>Email</div>
          <div className={mainStyles.fieldValue}>: {profile.email}</div>
          <div className={mainStyles.fieldName}>Phone</div>
          <div className={mainStyles.fieldValue}>: {profile.phoneNo}</div>
          <div className={mainStyles.fieldName}>Address </div>
          <div className={mainStyles.fieldValue}>: {profile.address}</div>
          <div className={mainStyles.fieldName}>City</div>
          <div className={mainStyles.fieldValue}>: {profile.city}</div>
          <div className={mainStyles.fieldName}>State</div>
          <div className={mainStyles.fieldValue}>: {profile.state}</div>
          <div className={mainStyles.fieldName}>Country</div>
          <div className={mainStyles.fieldValue}>: {profile.country}</div>
          <div className={mainStyles.fieldName}>PinCode</div>
          <div className={mainStyles.fieldValue}>: {profile.pincode}</div>
          {role === "CUSTOMER" && (
            <div className={styles.center}>
              <Button
                variant="conatined"
                color="primary"
                className={mainStyles.submitButton}
                onClick={(e) => {
                  navigate("/updateProfile");
                }}
              >
                Update
              </Button>
            </div>
          )}
        </div>
      )}
    </div>
  );
};

export default Profile;
