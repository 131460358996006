import { useEffect, useState } from "react";
import styles from "./booking.module.css";
import CarWashBookingDetail from "./CarWashBookingDetail";
import CarWash from "../CarWash/CarWash";
import { api } from "../../config/apiConfig";
import { Button, TextField } from "@mui/material";
import mainStyles from "../Main.module.css";
import AppPagination from "../Pagination/AppPagination";
import { useNavigate } from "react-router-dom";
import SearchIcon from "@mui/icons-material/Search";

export default function CarWashBookingList() {
  const [carWashBookings, setCarWashBookings] = useState([]);

  const [showCarWashBookingList, setShowCarWashBookingList] = useState(false);
  const [showCarWash, setShowCarWash] = useState(false);
  const [showCarWashBookingDetail, setShowCarWashDetail] = useState(false);
  const navigate = useNavigate();
  const [page, setPage] = useState(0);
  const [bookingId, setBookingId] = useState("");
  const [totalPages, setTotalPages] = useState(0);

  useEffect(() => {
    api
      .get("/api/bookings/carWash?page=" + page + "&bookingId=" + bookingId)
      .then((res) => {
        setCarWashBookings(res.data.carWashes);
        setTotalPages(res.data.totalPages);
        setShowCarWashBookingList(true);
      });
  }, [page, bookingId]);

  const showCarWashPage = (bookingId) => {
    navigate("/carWash?bookingId=" + bookingId);
  };

  const showCarWashDetailPage = (bookingId) => {
    navigate("/carWashBookingDetail?bookingId=" + bookingId);
  };

  return (
    <div>
      <header className={mainStyles.header}>Car Wash Booking List</header>
      <div className={styles.itemsRoot}>
        <div className={styles.searchBox}>
          <div>
            <TextField
              id="bookingId"
              label="Booking Id"
              variant="outlined"
              sx={{ borderRadius: "20px" }}
              onBlur={(e) => {
                setBookingId(e.target.value);
              }}
            />
          </div>
          <div className={styles.searchIcon}>
            <SearchIcon />
          </div>
        </div>
        {showCarWashBookingList && (
          <div>
            <div>
              {carWashBookings.map((booking, index) => {
                return (
                  <div key={index} className={styles.serviceItem}>
                    <div className={styles.fieldName}>Booking #</div>
                    <div className={styles.fieldValue}>
                      {booking.bookingNumber}
                    </div>
                    <div className={styles.fieldName}>Customer Name</div>
                    <div className={styles.fieldValue}>
                      {booking.customerFirstName} {booking.customerLastName}
                    </div>
                    <div className={styles.fieldName}>Model</div>
                    <div className={styles.fieldValue}>
                      {booking.brand} {booking.carModel}
                    </div>
                    <div className={styles.fieldName}>Status</div>
                    <div className={styles.fieldValue}>BOOKED</div>
                    {/* <div className={styles.field}><a href={`carWashBookingDetail?bookingId=${booking.bookingId}`}>View More</a></div>
                                <div className={styles.field}><a href={`carWash?bookingId=${booking.bookingId}`}>Create Car Wash</a></div> */}

                    <div className={styles.serviceButtonContainer}>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) =>
                          showCarWashDetailPage(booking.bookingId)
                        }
                      >
                        View
                      </Button>
                      <Button
                        variant="contained"
                        color="primary"
                        size="small"
                        onClick={(e) => showCarWashPage(booking.bookingId)}
                      >
                        Create Car Wash
                      </Button>
                    </div>
                  </div>
                );
              })}
              <AppPagination setPage={setPage} totalPages={totalPages} />
            </div>
          </div>
        )}
      </div>
    </div>
  );
}
