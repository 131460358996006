import { useEffect, useState } from "react";
import styles from "./UsedBike.module.css";
import { API_HOST_URL } from "../../config/apiConfig";
import { Footer } from "../Footer/Footer";
import { HeaderPlain } from "../Header/HeaderPlain";
import UsedBikeDetailShort from "./UsedBikeDetailShort";
import mainStyles from "../Main.module.css";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import AppPagination from "../Pagination/AppPagination";
import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../config/apiConfig";
import axios from "axios";
import { useNavigate } from "react-router-dom";

export default function UsedBikeListShort({ modelId = "" }) {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bikeNames, setBikeNames] = useState([]);
  const [model, setModel] = useState(modelId);
  const navigate = useNavigate();

  useEffect(() => {
    axios.get(API_HOST_URL + "/bikeModels/names").then((res) => {
      setBikeNames(res.data.bikeModelNames);
    });

    axios
      .get(API_HOST_URL + "/usedBikes/all?model=" + model + "&page=" + page)
      .then((res) => {
        setBikes(res.data.usedBikes);
        setTotalPages(res.data.totalPages);
      });
  }, [page, model]);

  const handleViewMore = (e, bike) => {
    navigate("/usedBikeDetailShort?bikeId=" + bike);
  };
  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  const handleBikeChange = (modelObj) => {
    setModel(modelObj.id);
  };

  return (
    <div>
      {showBikeList && (
        <div>
          <HeaderPlain />
          <div>
            <picture>
              <source
                width={"100%"}
                media="(max-width: 465px)"
                height={"150"}
                srcset={bikeImage1}
              />
              <source
                width={"100%"}
                height={"150"}
                media="(min-width: 700px)"
                srcset={bikeImage2}
              />
              <img src={bikeImage1} alt="bike ad" />
            </picture>
          </div>

          <div>
            <div className={mainStyles.header}>Used Bikes</div>
            <div className={styles.mainContainer}>
              <Autocomplete
                onChange={(event, value) => handleBikeChange(value)}
                disablePortal
                {...defaultProps}
                sx={{
                  width: 300,
                  backgroundColor: "white",
                  borderRadius: "20px",
                  height: "40px",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Bike" />
                )}
              />
            </div>
            {bikes.map((bike) => (
              <div className={styles.propContainer}>
                <div className={styles.bikeDetailContainer}>
                  <div className={styles.bikeImageDiv}>
                    <img
                      onClick={(e) => handleViewMore(e, bike.bikeId)}
                      className={styles.bikeImage}
                      src={bike.imageUrl}
                      alt="left"
                    />
                  </div>
                  <div className={styles.bikeDetailDiv}>
                    <div className={styles.bikeDetail}>
                      <div className={styles.brand}>
                        {bike.brand} {bike.model}
                      </div>
                      <div className={styles.modelYear}>
                        {bike.modelYear} Model
                      </div>
                      <div className={styles.price}> ₹{bike.price}/-</div>
                      <div>
                        <div className={styles.locationIcon}>
                          <LocationOnIcon fontSize="medium" />
                        </div>
                        <div className={styles.city}>{bike.sellerCity}</div>
                      </div>
                      <div className={styles.linkContainer}>
                        {" "}
                        <span
                          onClick={(e) => handleViewMore(e, bike.bikeId)}
                          className={styles.link}
                        >
                          View More{" "}
                        </span>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            ))}

            <AppPagination totalPages={totalPages} setPage={setPage} />
          </div>
          <Footer />
        </div>
      )}
      {showBikeDetail && <UsedBikeDetailShort bikeId={bikeId} />}
    </div>
  );
}
