import { useEffect, useState } from "react";
import { API_HOST_URL } from "../Constant/Constant";
import styles from "./NewBike.module.css";
import { Footer } from "../Footer/Footer";
import NewBikeDetail from "./NewBikeDetail";
import mainStyles from "../Main.module.css";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";
import AppPagination from "../Pagination/AppPagination";
import { Button } from "@mui/material";
import UpdateNewBike from "./UpdateNewBike";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import useMediaQuery from "@mui/material/useMediaQuery";
import { useTheme } from "@mui/material/styles";

import TextField from "@mui/material/TextField";
import Autocomplete from "@mui/material/Autocomplete";
import { api } from "../../config/apiConfig";
import { useLocation, useNavigate } from "react-router-dom";

export default function NewBikeList() {
  const [bikes, setBikes] = useState([]);
  const [showBikeDetail, setShowBikeDetail] = useState(false);
  const [showBikeList, setShowBikeList] = useState(true);
  const [showBikeUpdate, setShowBikeUpdate] = useState(false);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bikeNames, setBikeNames] = useState([]);
  const [model, setModel] = useState("");
  const [open, setOpen] = useState(false);
  const navigate = useNavigate();
  const location = useLocation();

  const openConfirmationDialogBox = (e, bikeId) => {
    setBikeId(bikeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let role = localStorage.getItem("role");

  useEffect(() => {
    const modelId = new URLSearchParams(location.search).get("model");
    if (modelId) {
      setModel(modelId);
    }

    api.get("/api/bikeModels/names").then((res) => {
      setBikeNames(res.data.bikeModelNames);
    });
    loadBikes();
  }, [page, model]);

  const loadBikes = () => {
    api.get("/api/newBikes?model=" + model + "&page=" + page).then((res) => {
      setBikes(res.data.bikes);
      setTotalPages(res.data.totalPages);
    });
  };

  const handleUpdate = (e, bike) => {
    navigate("/updateNewBike?bikeId=" + bike);
  };

  const handleDelete = () => {
    api.put("/api/newBikes/" + bikeId + "/delete").then((res) => {
      setOpen(false);
      loadBikes();
      window.scroll(0, 0);
    });
  };

  const handleViewMore = (e, bike) => {
    navigate("/newBikeDetail?bikeId=" + bike);
  };

  const defaultProps = {
    options: bikeNames,
    getOptionLabel: (option) => option.name,
  };

  const handleBikeChange = (modelObj) => {
    setModel(modelObj.id);
  };

  return (
    <div>
      {showBikeList && (
        <div>
          <div>
            <picture>
              <source
                width={"100%"}
                media="(max-width: 465px)"
                height={"150"}
                srcset={bikeImage1}
              />
              <source
                width={"100%"}
                height={"150"}
                media="(min-width: 700px)"
                srcset={bikeImage2}
              />
              <img src={bikeImage1} alt="bike ad" />
            </picture>
          </div>
          <div>
            <div className={mainStyles.header}>New Bikes</div>
            <div className={styles.mainContainer}>
              <Autocomplete
                onChange={(event, value) => handleBikeChange(value)}
                disablePortal
                {...defaultProps}
                sx={{
                  width: 300,
                  backgroundColor: "white",
                  borderRadius: "20px",
                  height: "40px",
                }}
                renderInput={(params) => (
                  <TextField {...params} label="Search Bike" />
                )}
              />
            </div>

            {bikes.map((bike) => (
              <div>
                <div className={styles.propContainer}>
                  <div className={styles.bikeDetailContainer}>
                    <div className={styles.bikeImageDiv}>
                      <img
                        onClick={(e) => handleViewMore(e, bike.bikeId)}
                        className={styles.bikeImage}
                        src={bike.imageUrl}
                        alt="bikeImage"
                      />
                    </div>
                    <div className={styles.bikeDetailDiv}>
                      <div className={styles.bikeDetail}>
                        <div className={styles.brand}>
                          {bike.brand} {bike.model}
                        </div>
                        <div className={styles.modelYear}>
                          {bike.engineCapacity}{" "}
                        </div>
                        <div className={styles.price}>
                          {" "}
                          ₹{bike.exShowroomPrice}/-
                        </div>
                      </div>
                    </div>
                  </div>
                </div>

                <div className={styles.linkContainer}>
                  <Button
                    onClick={(e) => handleViewMore(e, bike.bikeId)}
                    className={styles.button}
                  >
                    View
                  </Button>
                  {role === "ADMIN" && (
                    <>
                      <Button
                        onClick={(e) => handleUpdate(e, bike.bikeId)}
                        className={styles.button}
                      >
                        Update
                      </Button>
                      <Button
                        onClick={(e) =>
                          openConfirmationDialogBox(e, bike.bikeId)
                        }
                        className={`${styles.button} ${styles.delete}`}
                      >
                        Delete
                      </Button>
                    </>
                  )}
                </div>
              </div>
            ))}
            <AppPagination setPage={setPage} totalPages={totalPages} />
            <Dialog
              open={open}
              onClose={handleClose}
              aria-labelledby="alert-dialog-title"
              aria-describedby="alert-dialog-description"
            >
              <DialogTitle id="alert-dialog-title">
                {"Delete Bike?"}
              </DialogTitle>
              <DialogContent>
                <DialogContentText>
                  Are you sure you want to delete this bike?
                </DialogContentText>
              </DialogContent>
              <DialogActions>
                <Button autoFocus onClick={handleClose}>
                  Cancel
                </Button>
                <Button
                  onClick={handleDelete}
                  autoFocus
                  className={`${styles.button} ${styles.delete}`}
                >
                  Delete
                </Button>
              </DialogActions>
            </Dialog>
          </div>

          <Footer />
        </div>
      )}
      {showBikeDetail && <NewBikeDetail bikeId={bikeId} />}
      {showBikeUpdate && <UpdateNewBike bikeId={bikeId} />}
    </div>
  );
}
