import { useEffect, useState } from "react";
import styles from "./CarModel.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { API_HOST_URL } from "../Constant/Constant";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function CarModel() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState("");

  useEffect(() => {
    api.get("/api/brands/").then((res) => {
      setIsLoading(false);
      setBrands(res.data);
    });
  }, []);

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const handleSubmit = (e) => {
    const carModel = { name, description, brandId };
    setIsLoading(true);

    api
      .post("/api/carModels/", carModel)
      .then((res) => {
        onSuccessfulSave();
      })
      .catch((error) => {
        onFailedSave();
      });
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div className={styles.mainDiv}>
      <header className={mainStyles.header}>Add Car Model</header>
      <form onSubmit={handleSubmit}>
        <div className={styles.ddlContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => setBrandId(e.target.value)}
          >
            <option> Select Brand </option>
            {brands.map((brand) => (
              <option key={brand.brandId} value={brand.brandId}>
                {brand.brandName}
              </option>
            ))}
          </select>
        </div>

        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            label="Enter Car Model "
          />
        </div>

        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            onChange={(e) => setDescription(e.target.value)}
            label="Enter Model Description"
            multiline
            rows={3}
          />
        </div>
        <div className={styles.componentContainer}>
          <Button
            className={styles.saveBtn}
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {" "}
            Save Car Model
          </Button>
        </div>
        <div className={styles.componentContainer}>
          {isSaved && (
            <div>
              <p className={mainStyles.success}>Car Model Saved Successfully</p>
            </div>
          )}
          {hasError && (
            <div>
              <p className={mainStyles.error}>
                Some error occurred while saving. Please try later.
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
