import { useDispatch, useSelector } from "react-redux";
import styles from "./Footer.module.css";
import { useNavigate } from "react-router-dom";
import { useEffect } from "react";
import { getUser } from "../../redux/state/Auth/Action";

export function Footer() {
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);
  const navigate = useNavigate();
  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt, navigate));
    }
  }, [jwt]);

  return (
    <div>
      {jwt && auth.user && (
        <div className={styles.footer}>
          <div>All rights reserved &copy; bluebike.in</div>
          <div>
            <a href="/contact">&nbsp; Contact &nbsp; </a>
          </div>
          <div>
            <a href="/aboutUs">&nbsp; About </a>
          </div>
        </div>
      )}
    </div>
  );
}
