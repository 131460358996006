import axios from "axios";

export const API_HOST_URL = "https://backend.bluebike.in";

export const api = axios.create({
  baseURL: API_HOST_URL,
  headers: {
    Authorization: `Bearer ${localStorage.getItem("jwt")}`,
    "Content-Type": "application/json",
  },
});
