import { useEffect, useState } from "react";
import styles from "./Workshop.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import validator from "validator";
import { API_HOST_URL, api } from "../../config/apiConfig";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import axios from "axios";

export default function Workshop() {
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);

  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");

  //States for form fields
  const [name, setName] = useState("");
  const [ownerName, setOwnerName] = useState();

  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState("");
  const [street, setStreet] = useState("");
  const [cityId, setCityId] = useState("");
  const [zipCode, setZipCode] = useState("");
  const [shopNumber, setShopNumber] = useState("");
  const [establishedOn, setEstablishedOn] = useState("");

  //Validators state
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidAlternatePhone, setIsValidAlternatePhone] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [workshopId, setWorkshopId] = useState("");
  const [image1, setImage1] = useState();
  const [image2, setImage2] = useState();
  const [image3, setImage3] = useState();
  const [image4, setImage4] = useState();
  const [image5, setImage5] = useState();

  const [image1Uploaded, setImage1Uploaded] = useState(false);
  const [image2Uploaded, setImage2Uploaded] = useState(false);
  const [image3Uploaded, setImage3Uploaded] = useState(false);
  const [image4Uploaded, setImage4Uploaded] = useState(false);
  const [image5Uploaded, setImage5Uploaded] = useState(false);

  const [image1UploadFail, setImage1UploadFail] = useState(false);
  const [image2UploadFail, setImage2UploadFail] = useState(false);
  const [image3UploadFail, setImage3UploadFail] = useState(false);
  const [image4UploadFail, setImage4UploadFail] = useState(false);
  const [image5UploadFail, setImage5UploadFail] = useState(false);

  useEffect(() => {
    axios(API_HOST_URL + "/countries/").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const handleImage1 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image1);
    formData.append("imageNumber", "IMAGE1");
    let saved = saveImage(formData);
    if (saved) setImage1Uploaded(true);
    else setImage1UploadFail(true);
  };

  const handleImage2 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image2);
    formData.append("imageNumber", "IMAGE2");
    let saved = saveImage(formData);
    if (saved) setImage2Uploaded(true);
    else setImage2UploadFail(true);
  };

  const handleImage3 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image3);
    formData.append("imageNumber", "IMAGE3");
    let saved = saveImage(formData);
    if (saved) setImage3Uploaded(true);
    else setImage3UploadFail(true);
  };

  const handleImage4 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image4);
    formData.append("imageNumber", "IMAGE4");
    let saved = saveImage(formData);
    if (saved) setImage4Uploaded(true);
    else setImage4UploadFail(true);
  };

  const handleImage5 = (e) => {
    e.preventDefault();

    const formData = new FormData();
    formData.append("file", image5);
    formData.append("imageNumber", "IMAGE5");
    let saved = saveImage(formData);
    if (saved) setImage5Uploaded(true);
    else setImage5UploadFail(true);
  };

  const saveImage = (params) => {
    if (workshopId) {
      params.append("workshopId", workshopId);
    } else {
      params.append("workshopId", "");
    }

    var saved = true;

    api
      .post("/api/workshops/images/", params, {
        headers: {
          "Content-Type": "multipart/form-data",
        },
      })
      .then((res) => {
        setWorkshopId(res.data.workshopId);
        saved = true;
      })
      .catch((error) => {
        console.log(error);
        saved = false;
      });
    return saved;
  };

  const loadStates = (e, countryId) => {
    axios.get(API_HOST_URL + "/states/" + countryId).then((res) => {
      setCountryId(countryId);
      setStates(res.data);
    });
  };

  const loadCities = (e, stateId) => {
    axios.get(API_HOST_URL + "/cities/" + stateId).then((res) => {
      setStateId(stateId);
      setCities(res.data);
    });
  };

  // Email Validation
  const validateEmail = (e) => {
    setEmail(e.target.value);

    if (validator.isEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  // Phone Number Validation
  const validatePhone = (e) => {
    setPhoneNumber(e.target.value);

    if (validator.isMobilePhone(phoneNumber)) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  const validateAlternatePhone = (e) => {
    setAlternatePhoneNumber(e.target.value);

    if (validator.isMobilePhone(alternatePhoneNumber)) {
      setIsValidAlternatePhone(true);
    } else {
      setIsValidAlternatePhone(false);
    }
  };

  const isValidForm = (e) => {
    return (
      workshopId &&
      isValidPhone &&
      isValidAlternatePhone &&
      isValidEmail &&
      (image1Uploaded ||
        image2Uploaded ||
        image3Uploaded ||
        image4Uploaded ||
        image5Uploaded)
    );
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setName("");
    setOwnerName("");
    setEmail("");
    setPhoneNumber("");
    setAlternatePhoneNumber("");
    setStreet("");
    setCityId("");
    setZipCode("");
    setShopNumber("");
    setEstablishedOn("");
    setWorkshopId("");
    setImage1Uploaded(false);
    setImage2Uploaded(false);
    setImage3Uploaded(false);
    setImage4Uploaded(false);
    setImage5Uploaded(false);
    setImage1UploadFail(false);
    setImage2UploadFail(false);
    setImage3UploadFail(false);
    setImage4UploadFail(false);
    setImage5UploadFail(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const workshop = {
      workshopId,
      name,
      ownerName,
      shopNumber,
      email,
      phoneNumber,
      alternatePhoneNumber,
      street,
      cityId,
      stateId,
      countryId,
      zipCode,
      establishedOn,
    };

    if (isValidForm()) {
      api
        .post("/api/workshops/", workshop)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <header className={mainStyles.header}>Add Workshop</header>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setName(e.target.value)}
          label="Enter Workshop/Garage Name"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onChange={(e) => setOwnerName(e.target.value)}
          label="Enter Owner Name"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="phone"
          variant="standard"
          onChange={(e) => validatePhone(e)}
          label="Enter Phone Number"
        />
      </div>
      <div className={styles.componentContainer}>
        {!isValidPhone && (
          <p className="errorMessage">Please enter a valid Phone number</p>
        )}
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="phone"
          variant="standard"
          onChange={(e) => validateAlternatePhone(e)}
          label="Enter alternate Phone Number"
        />
      </div>
      <div className={styles.componentContainer}>
        {!isValidAlternatePhone && (
          <p className="errorMessage">
            Please enter a valid alternate Phone number
          </p>
        )}
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setShopNumber(e.target.value)}
          label="Enter Shop Number"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setStreet(e.target.value)}
          label="Enter Street"
        />
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          type="email"
          variant="standard"
          onChange={(e) => validateEmail(e)}
          label="Enter Email Id"
        />
      </div>
      <div className={styles.componentContainer}>
        {!isValidEmail && (
          <p className="errorMessage">Please enter a valid email id</p>
        )}
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => loadStates(e, e.target.value)}
        >
          <option> Select Country </option>
          {countries.map((country) => (
            <option key={country.id} value={country.id}>
              {country.countryName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => loadCities(e, e.target.value)}
        >
          <option> Select State </option>
          {states.map((state) => (
            <option key={state.stateId} value={state.stateId}>
              {state.stateName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.ddlContainer}>
        <select
          className={styles.ddl}
          onChange={(e) => setCityId(e.target.value)}
        >
          <option> Select City </option>
          {cities.map((city) => (
            <option key={city.cityId} value={city.cityId}>
              {city.cityName}
            </option>
          ))}
        </select>
      </div>
      <div className={styles.componentContainer}>
        <TextField
          className={styles.component}
          variant="standard"
          onBlur={(e) => setZipCode(e.target.value)}
          label="Enter Zip code"
        />
      </div>

      <div className={styles.componentContainer}>
        <label className={styles.component} for="birthday">
          Started On{" "}
        </label>
        <input
          type="date"
          onBlur={(e) => setEstablishedOn(e.target.value)}
          id="establishedOn"
          name="establishedOn"
        />
      </div>

      <Divider>
        <Chip label="Image 1" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleImage1} encType="multipart/form-data">
          <input type="file" onChange={(e) => setImage1(e.target.files[0])} />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {image1Uploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {image1UploadFail && <p className="inline"> Image upload Failed</p>}
        </form>
      </div>
      <Divider>
        <Chip label="Image 2" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleImage2} encType="multipart/form-data">
          <input type="file" onChange={(e) => setImage2(e.target.files[0])} />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {image2Uploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {image2UploadFail && <p className="inline"> Image upload Failed</p>}
        </form>
      </div>

      <Divider>
        <Chip label="Image 3" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleImage3} encType="multipart/form-data">
          <input type="file" onChange={(e) => setImage3(e.target.files[0])} />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {image3Uploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {image3UploadFail && <p className="inline"> Image upload Failed</p>}
        </form>
      </div>

      <Divider>
        <Chip label="Image 4" size="small" />
      </Divider>
      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleImage4} encType="multipart/form-data">
          <input type="file" onChange={(e) => setImage4(e.target.files[0])} />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {image4Uploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {image4UploadFail && <p className="inline"> Image upload Failed</p>}
        </form>
      </div>

      <Divider>
        <Chip label="Image 5" size="small" />
      </Divider>

      <div className={styles.uploadFormContainer}>
        <form onSubmit={handleImage5} encType="multipart/form-data">
          <input type="file" onChange={(e) => setImage5(e.target.files[0])} />
          <Button type="submit" variant="contained" startIcon={<SaveIcon />}>
            {" "}
            Upload{" "}
          </Button>
          {image5Uploaded && (
            <p className="inline"> Image uploaded Successfully</p>
          )}
          {image5UploadFail && <p className="inline"> Image upload Failed</p>}
        </form>
      </div>

      <Divider></Divider>
      <div className={styles.componentContainer}>
        <Button
          className={styles.saveBtn}
          type="submit"
          variant="contained"
          onClick={handleSubmit}
          startIcon={<SaveIcon />}
        >
          {" "}
          Save Workshop
        </Button>
      </div>
      <div className={styles.componentContainer}>
        {hasError && (
          <p className={mainStyles.error}>
            Please enter all the mandatory fields with correct value
          </p>
        )}

        {isSaved && (
          <div>
            <p className={mainStyles.success}>Workshop Saved Successfully</p>
          </div>
        )}
      </div>
    </div>
  );
}
