import logo from "./logo.svg";
import "./App.css";
import BlueBikeRouter from "./components/Routers/BlueBikeRouter";
import { Route, Routes } from "react-router-dom";

function App() {
  return (
    (document.title = "Blue Bike"),
    (
      <div className="App">
        <Routes>
          <Route path="/*" element={<BlueBikeRouter />} />
        </Routes>
      </div>
    )
  );
}

export default App;
