import React from "react";
import AliceCarousel from "react-alice-carousel";
import "react-alice-carousel/lib/alice-carousel.css";

const MainCarousel = ({ imageList }) => {
  const responsive = {
    0: { items: 1 },
    720: { items: 3 },
    // 1024: { items: 5.5 },
  };

  const items = imageList.map((item) => (
    <img
      src={item.imageUrl}
      alt=""
      className="cursor-pointer"
      role="presentation"
      height={350}
      width={350}
    />
  ));

  return (
    <AliceCarousel
      items={items}
      disableButtonsControls
      autoPlay
      autoPlayInterval={2000}
      infinite
      responsive={responsive}
    />
  );
};

export default MainCarousel;
