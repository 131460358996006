import React, { useEffect } from "react";

import styles from "./HomePage.module.css";
import mainStyles from "../Main.module.css";
import bikesImage from "../bikes.png";
import bikeImage1 from "./images/bluebike_ad.jpg";
import bikeImage2 from "./images/bluebike_ad2.jpg";

import { styled } from "@mui/material/styles";
import Grid from "@mui/material/Grid";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import NewBikeList from "../NewBike/NewBikeList";
import UsedBikeList from "../UsedBike/UsedBikeList";
import UsedBike from "../UsedBike/UsedBike";
import { Button } from "@mui/material";
import { useNavigate } from "react-router-dom";

const UserHome = () => {
  const [showNewBike, setShowNewBike] = React.useState(false);
  const [showUsedBike, setShowUsedBike] = React.useState(false);
  const [showHome, setShowHome] = React.useState(true);
  const [showSellBike, setShowSellBike] = React.useState(false);
  const [modelId, setModelId] = React.useState("");
  const navigate = useNavigate();
  const jwt = localStorage.getItem("jwt");

  useEffect(() => {
    if (!jwt) {
      navigate("/login");
    }
  }, []);

  const Item = styled(Paper)(({ theme }) => ({
    backgroundColor: "#fff",
    ...theme.typography.body2,
    padding: theme.spacing(1),
    textAlign: "center",
    color: theme.palette.text.secondary,
    ...theme.applyStyles("dark", {
      backgroundColor: "#1A2027",
    }),
  }));

  const handleIconClick = (e, id) => {
    navigate("/newBikeList?model=" + id);
  };

  const handleSellBikeClick = (e) => {
    navigate("/usedBike");
  };

  return (
    <div>
      {showHome && (
        <div>
          {" "}
          <div className={styles.root}>
            {/* <div>
              <img
                src={bikesImage}
                alt="Bikes"
                className={mainStyles.bigImage}
              />
            </div> */}
            <div>
              <picture>
                <source
                  width={"100%"}
                  media="(max-width: 465px)"
                  height={"150"}
                  srcset={bikeImage1}
                />
                <source
                  width={"100%"}
                  height={"150"}
                  media="(min-width: 700px)"
                  srcset={bikeImage2}
                />
                <img src={bikeImage1} alt="bike ad" />
              </picture>
            </div>
            <div className={styles.buttonContainer1}>
              <div
                onClick={(e) => {
                  navigate("/newBikeList");
                }}
                className={styles.bikeButton}
              >
                <span>New&nbsp; Bike</span>
              </div>
              <div
                onClick={(e) => {
                  navigate("/usedBikeList");
                }}
                className={styles.bikeButton}
              >
                <span>Used Bike</span>
              </div>
            </div>
            {/* <div className={styles.buttonContainer2}>
              <div
                onClick={(e) => {
                  navigate("/carWashBooking");
                }}
                className={styles.bikeButton}
              >
                <span>Car Wash</span>
              </div>
              <div
                onClick={(e) => {
                  navigate("/bikeServiceBooking");
                }}
                className={styles.bikeButton}
              >
                <span>Bike Service</span>
              </div>
            </div> */}
            <div className={styles.iconsGrid}>
              <Box sx={{ width: "100%" }}>
                <Grid
                  container
                  rowSpacing={1}
                  columnSpacing={{ xs: 1, sm: 2, md: 4 }}
                  sx={{
                    justifyContent: "center",
                    alignItems: "center",
                  }}
                >
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-8337c86b-0928-4560-bbff-50cc61a5c04e"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.tvsIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-026f7bbf-3f3c-4908-baa0-0e243ce4dbf0"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.bajajIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-0531537c-de13-4b6a-9b98-bf41ad1bbbea"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.royalEnfieldIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-b4cd63d3-b0b4-48a5-afbe-f10e6a7d6bce"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.yamahaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-69cea3d6-1521-452d-95b6-ee02266f276f"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.hondaIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-bf68364d-f792-415d-a666-0d157d62647c"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.suzukiIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-33422ee8-c859-4628-8f0e-0bf553f9a484"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.mahindraIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-623dddd3-11c7-4004-9eec-c02183710d6b"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.heroIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                  <Grid item xs={3.2}>
                    <Item>
                      <div
                        onClick={(e) =>
                          handleIconClick(
                            e,
                            "brand-e8f1e471-0dfd-4fe3-aee7-9b21f7360fc1"
                          )
                        }
                        className={styles.iconContainer}
                      >
                        <div className={styles.ktmIcon}></div>
                      </div>
                    </Item>
                  </Grid>
                </Grid>
              </Box>
            </div>
            <div className={styles.bottomButton}>
              <Button
                variant="contained"
                color="primary"
                size="large"
                onClick={(e) => {
                  handleSellBikeClick(e);
                }}
              >
                Sell Bike
              </Button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

export default UserHome;
