import { useEffect, useState } from "react";
import styles from "./BikeModel.module.css";
import mainStyles from "../Main.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import { API_HOST_URL } from "../Constant/Constant";
import loadingImage from "../loading.gif";
import { api } from "../../config/apiConfig";

export default function BikeModel() {
  const [name, setName] = useState("");
  const [description, setDescription] = useState("");
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [hasError, setHasError] = useState(false);

  const [brands, setBrands] = useState([]);
  const [brandId, setBrandId] = useState("");

  useEffect(() => {
    api
      .get("/api/brands/")

      .then((res) => {
        setIsLoading(false);
        setBrands(res.data);
      })
      .catch((error) => {
        onFailedSave();
      });
  }, []);

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setName("");
    setDescription("");
    setBrandId("");
  };

  const validateForm = () => {
    if (name === "" || brandId === "") {
      return false;
    }
    return true;
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    if (validateForm()) {
      const bikeModel = { name, description, brandId };

      api
        .post("/api/bikeModels/", bikeModel)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        <header className={mainStyles.header}>Add Bike Model</header>
        <div className={styles.ddlContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => setBrandId(e.target.value)}
          >
            <option> Select Brand </option>ßß
            {brands.map((brand) => (
              <option key={brand.brandId} value={brand.brandId}>
                {brand.brandName}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setName(e.target.value)}
            label="Enter Bike Model "
          />
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            onChange={(e) => setDescription(e.target.value)}
            label="Enter Model Description"
            multiline
            rows={3}
          />
        </div>
        <div className={styles.componentContainer}>
          <Button
            className={styles.saveBtn}
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {" "}
            Save Bike Model
          </Button>
        </div>
        <div className={styles.componentContainer}>
          {isSaved && (
            <div>
              <p className={mainStyles.success}>
                Bike Model Saved Successfully
              </p>
            </div>
          )}
          {hasError && (
            <div>
              <p className={mainStyles.error}>
                Some error occurred while saving.{" "}
              </p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
