import { useEffect, useState } from "react";
import styles from "./BikeServicing.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function BikeServicingPickedUp() {
  const [bookings, setBookings] = useState([]);
  const [bikeService, setBikeService] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [remarks, setRemarks] = useState();
  const [pickedUpAt, setPickedUpAt] = useState();
  const [bookingId, setBookingId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialData = () => {
    const getCarWashBookingIdList = api.get(
      "/api/bookings/bookingNumber/bikeServicing"
    );
    const getWorkers = api.get("/api/workers/");
    const getWorkshops = api.get("/api/workshops/idAndName");

    axios.all([getCarWashBookingIdList, getWorkers, getWorkshops]).then(
      axios.spread((...allData) => {
        setBookings(allData[0].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const validateForm = () => {
    if (remarks === "" || pickedUpAt === undefined || pickedUpAt === "") {
      return false;
    }

    return true;
  };

  const loadBikeServicing = (id) => {
    setBookingId(id);
    api
      .get("/api/bikeService/" + id)

      .then((res) => {
        setBikeService(res.data);
        setRemarks(res.data.remarks);
        setPickedUpAt(dayjs(new Date()));
      });
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setRemarks("");
    setPickedUpAt("");
    setBookingId("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();

    setIsLoading(true);
    if (validateForm()) {
      const bikeServicing = {
        bookingId,
        pickedUpAt,
        remarks,
      };

      api
        .put("/api/bikeService/pickedUp", bikeServicing)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={styles.carWashContainerDiv}>
          <header className={mainStyles.header}>
            Bike Servicing - Pickup From Customer
          </header>
          {bookings && (
            <div className={styles.bookingDeailContainer}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Booking #</div>
                <div className={styles.bookingValue}>
                  <select
                    className={styles.ddl}
                    onChange={(e) => loadBikeServicing(e.target.value)}
                  >
                    <option> Select Booking Id </option>
                    {bookings.map((booking) => (
                      <option key={booking.bookingId} value={booking.bookingId}>
                        {booking.bookingNumber}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          {bikeService && (
            <div className={styles.container}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Name:</div>
                <div className={styles.bookingValue}>
                  {bikeService.customerName}{" "}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Model:</div>
                <div className={styles.bookingValue}>{bikeService.model}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Color:</div>
                <div className={styles.bookingValue}>{bikeService.color}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Registration #:</div>
                <div className={styles.bookingValue}>
                  {bikeService.registrationNumber}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Address:</div>
                <div className={styles.bookingValue}>{bikeService.address}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Servicing Status:</div>
                <div className={styles.bookingValue}>{bikeService.status}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Remaks:</div>
                <div className={styles.bookingValue}>
                  <TextField
                    multiline
                    rows={2}
                    onBlur={(e) => setRemarks(e.target.value)}
                    label="Enter Remarks"
                    defaultValue={bikeService.remarks}
                  />
                </div>
              </div>

              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>PickedUp At:</div>
                <div className={styles.bookingValue}>
                  <div className={styles.ddlContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <DemoItem label="Schedule Date/Time">
                          <MobileDateTimePicker
                            className={styles.dateTime}
                            onChange={(e) => setPickedUpAt(e)}
                            defaultValue={dayjs(new Date())}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <div className={styles.componentContainer}>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                >
                  {" "}
                  Update
                </Button>
              </div>

              {hasError && (
                <p className={mainStyles.error}>
                  Please fill all the mandatory fields.
                </p>
              )}
              {isSaved && (
                <p className={mainStyles.success}>
                  {" "}
                  Bike Servicing status updated successfully
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
