import React from "react";
import { Route, Routes } from "react-router-dom";
import LandingPage from "../LandingPage/LandingPage";
import LoginPage from "../Login/LoginPage";
import Brand from "../Brand/Brand";
import State from "../State/State";
import City from "../City/City";
import { Footer } from "../Footer/Footer.jsx";
import Country from "../Country/Country";
import Customer from "../Customer/Customer";
import Operator from "../Operator/Operator";
import Worker from "../Worker/Worker";
import BikeModel from "../BikeModel/BikeModel";
import NewBike from "../NewBike/NewBike";
import NewBikeDetail from "../NewBike/NewBikeDetail";
import UsedBike from "../UsedBike/UsedBike";
import UsedBikeDetail from "../UsedBike/UsedBikeDetail";
import Workshop from "../Workshop/Workshop";
import BikeServiceBooking from "../Booking/BikeServiceBooking";
import CarModel from "../CarModel/CarModel";
import CarWashBooking from "../Booking/CarWashBooking";
import CarWashBookingList from "../Booking/CarWashBookingList";
import CarWashBookingDetail from "../Booking/CarWashBookingDetail";
import BikeServiceBookingDetail from "../Booking/BikeServiceBookingDetail";
import BikeServiceBookingList from "../Booking/BikeServiceBookingList";
import Admin from "../Admin/Admin";
import CarWash from "../CarWash/CarWash";
import CarWashStatusUpdate from "../CarWash/CarWashStatusUpdate";
import BikeServicing from "../BikeServicing/BikeServicing";
import BikeServicingStatusUpdate from "../BikeServicing/BikeServicingStatusUpdate";
import UsedBikeListShort from "../UsedBike/UsedBikeListShort";
import NewBikeListShort from "../NewBike/NewBikeListShort.jsx";
import WorkshopList from "../Workshop/WorkshopList";
import ReviewCarWash from "../Review/ReviewCarWash.jsx";
import ReviewBikeServicing from "../Review/ReviewBikeServicing.jsx";
import ContactForm from "../FooterPages/Contact.jsx";
import AboutUs from "../FooterPages/About.jsx";
import ResetPassword from "../Login/ResetPassword.jsx";
import NewBikeList from "../NewBike/NewBikeList.jsx";
import UsedBikeList from "../UsedBike/UsedBikeList.jsx";
import UsedBikes from "../Customer/UsedBikes.jsx";
import UserHome from "../HomePage/UserHome.jsx";
import HeaderMain from "../Header/HeaderMain.jsx";
import UpdateUsedBike from "../UsedBike/UpdateUsedBike.jsx";
import WorkshopDetail from "../Workshop/WorkshopDetail.jsx";
import StatusUpdate from "../Status/StatusUpdate.jsx";
import BikeServicingPickedUp from "../BikeServicing/BikeServicingPickedUp.jsx";
import BikeServicingDroppedAtWorkshop from "../BikeServicing/BikeServicingDroppedAtWorkshop.jsx";
import BikeServicingComplete from "../BikeServicing/BikeServicingComplete.jsx";
import BikeServicingCancelled from "../BikeServicing/BikeServicingCancelled.jsx";
import CarWashStart from "../CarWash/CarWashStart.jsx";
import CarWashComplete from "../CarWash/CarWashComplete.jsx";
import CarWashCancel from "../CarWash/CarWashCancel.jsx";
import ReviewActions from "../Review/ReviewActions.jsx";
import ModerateReview from "../Review/ModerateReview.jsx";
import AggregateWorkshopRating from "../Review/AggregateWorkshopRating.jsx";
import Profile from "../Profile/Profile.jsx";
import UpdateProfile from "../Profile/UpdateProfile.jsx";
import NewBikeDisplayStatusUpdate from "../NewBike/NewBikeDisplayStatusUpdate.jsx";
import UsedBikeDisplayStatusUpdate from "../UsedBike/UsedBikeDisplayStatusUpdate.jsx";
import NewBikeDetailShort from "../NewBike/NewBikeDetailShort.jsx";
import UpdateNewBike from "../NewBike/UpdateNewBike.jsx";
import UsedBikeDetailShort from "../UsedBike/UsedBikeDetailShort.jsx";
import UpdateWorkshop from "../Workshop/UpdateWorkshop.jsx";

const BlueBikeRouter = () => {
  return (
    <div>
      <div>
        <HeaderMain />
      </div>
      <Routes>
        <Route path="/" element={<LandingPage />} />
        <Route path="login" element={<LoginPage />} />
        <Route path="brand" element={<Brand />} />
        <Route path="country" element={<Country />} />
        <Route path="state" element={<State />} />
        <Route path="city" element={<City />} />
        <Route path="customer" element={<Customer />} />
        <Route path="operator" element={<Operator />} />
        <Route path="worker" element={<Worker />} />
        <Route path="bikeModel" element={<BikeModel />} />
        <Route path="newBike" element={<NewBike />} />
        <Route path="home" element={<UserHome />} />
        <Route path="landingPage" element={<LandingPage />} />

        <Route path="newBikeDetail" element={<NewBikeDetail />} />
        <Route path="usedBike" element={<UsedBike />} />
        <Route path="usedBikeDetail" element={<UsedBikeDetail />} />
        <Route path="workshop" element={<Workshop />} />
        <Route path="carModel" element={<CarModel />} />
        <Route path="bikeServiceBooking" element={<BikeServiceBooking />} />
        <Route path="carWashBooking" element={<CarWashBooking />} />
        <Route path="carWashBookingList" element={<CarWashBookingList />} />
        <Route path="carWashBookingDetail" element={<CarWashBookingDetail />} />
        <Route path="newBikeList" element={<NewBikeList />} />
        <Route path="usedBikeList" element={<UsedBikeList />} />
        <Route path="myBikes" element={<UsedBikes />} />
        <Route
          path="bikeServiceBookingList"
          element={<BikeServiceBookingList />}
        />
        <Route
          path="bikeServiceBookingDetail"
          element={<BikeServiceBookingDetail />}
        />
        <Route path="admin" element={<Admin />} />
        <Route path="carWash" element={<CarWash />} />
        <Route path="health" element={<div>Health Check</div>} />
        <Route path="carWashStatusUpdate" element={<CarWashStatusUpdate />} />
        <Route path="bikeServicing" element={<BikeServicing />} />
        <Route
          path="bikeServicingStatusUpdate"
          element={<BikeServicingStatusUpdate />}
        />
        <Route path="newBikeListShort" element={<NewBikeListShort />} />
        <Route path="usedBikeListShort" element={<UsedBikeListShort />} />
        <Route path="workshopList" element={<WorkshopList />} />
        <Route path="reviewCarWash" element={<ReviewCarWash />} />
        <Route path="reviewBikeServicing" element={<ReviewBikeServicing />} />
        <Route path="contact" element={<ContactForm />} />
        <Route path="aboutUs" element={<AboutUs />} />
        <Route path="resetPassword" element={<ResetPassword />} />
        <Route path="updateUsedBike" element={<UpdateUsedBike />} />
        <Route path="workshopDetail" element={<WorkshopDetail />} />
        <Route path="statusUpdate" element={<StatusUpdate />} />
        <Route path="bikeServicePickUp" element={<BikeServicingPickedUp />} />
        <Route
          path="bikeServiceDrop"
          element={<BikeServicingDroppedAtWorkshop />}
        />
        <Route path="bikeServiceComplete" element={<BikeServicingComplete />} />
        <Route path="bikeServiceCancel" element={<BikeServicingCancelled />} />
        <Route path="carWashStart" element={<CarWashStart />} />
        <Route path="carWashComplete" element={<CarWashComplete />} />
        <Route path="carWashCancel" element={<CarWashCancel />} />
        <Route path="reviewActions" element={<ReviewActions />} />
        <Route path="moderateReview" element={<ModerateReview />} />
        <Route path="profile" element={<Profile />} />
        <Route
          path="aggregateWorkshopRating"
          element={<AggregateWorkshopRating />}
        />
        <Route path="updateProfile" element={<UpdateProfile />} />
        <Route
          path="newBikeDisplayStatusUpdate"
          element={<NewBikeDisplayStatusUpdate />}
        />
        <Route
          path="usedBikeDisplayStatusUpdate"
          element={<UsedBikeDisplayStatusUpdate />}
        />
        <Route path="newBikeDetailShort" element={<NewBikeDetailShort />} />
        <Route path="updateNewBike" element={<UpdateNewBike />} />
        <Route path="newBikeDetail" element={<NewBikeDetail />} />
        <Route path="usedBikeDetail" element={<UsedBikeDetail />} />
        <Route path="usedBikeDetailShort" element={<UsedBikeDetailShort />} />
        <Route path="updateWorkshop" element={<UpdateWorkshop />} />
      </Routes>
      <div>
        <Footer />
      </div>
    </div>
  );
};

export default BlueBikeRouter;
