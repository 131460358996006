import { useEffect, useState } from "react";
import styles from "./CarWash.module.css";
import { API_HOST_URL } from "../../config/apiConfig";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function CarWashCancel() {
  const [bookings, setBookings] = useState([]);
  const [carWash, setCarWash] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [remarks, setRemarks] = useState();
  const [bookingId, setBookingId] = useState();
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialData = () => {
    const getCarWashBookingIdList = api.get(
      "/api/bookings/bookingNumber/carWash"
    );

    axios.all([getCarWashBookingIdList]).then(
      axios.spread((...allData) => {
        setBookings(allData[0].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const validateForm = () => {
    if (remarks === "" || bookingId === undefined || bookingId === "") {
      return false;
    }

    return true;
  };

  const loadCarWash = (id) => {
    setBookingId(id);
    api
      .get("/api/carWash/" + id)

      .then((res) => {
        setCarWash(res.data);
        setRemarks(res.data.remarks);
      });
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBookingId();
    setRemarks();
    setIsSaved(false);
    setHasError(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      const carWash = {
        bookingId,
        remarks,
      };

      api
        .put("/api/carWash/cancel", carWash)

        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={styles.carWashContainerDiv}>
          <header className={mainStyles.header}>Car Wash - Cancel</header>
          {bookings && (
            <div className={styles.bookingDeailContainer}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Booking #</div>
                <div className={styles.bookingValue}>
                  <select
                    className={styles.ddl}
                    onChange={(e) => loadCarWash(e.target.value)}
                  >
                    <option> Select Booking Id </option>
                    {bookings.map((booking) => (
                      <option key={booking.bookingId} value={booking.bookingId}>
                        {booking.bookingNumber}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          {carWash && (
            <div className={styles.container}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Name:</div>
                <div className={styles.bookingValue}>
                  {carWash.customerFullName}{" "}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Model:</div>
                <div className={styles.bookingValue}>{carWash.carModel}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Color:</div>
                <div className={styles.bookingValue}>{carWash.color}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Address:</div>
                <div className={styles.bookingValue}>{carWash.address}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Servicing Status:</div>
                <div className={styles.bookingValue}>{carWash.status}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Worker:</div>
                <div className={styles.bookingValue}>{carWash.worker}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Remarks:</div>
                <div className={styles.bookingValue}>
                  <TextField
                    multiline
                    rows={2}
                    onBlur={(e) => setRemarks(e.target.value)}
                    label="Enter Remarks"
                    defaultValue={carWash.remarks}
                  />
                </div>
              </div>

              <div className={styles.component}>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                >
                  {" "}
                  Update
                </Button>
              </div>

              {hasError && (
                <p className={mainStyles.error}>
                  Please fill all the mandatory fields.
                </p>
              )}
              {isSaved && (
                <p className={mainStyles.success}>
                  {" "}
                  Car Wash status updated successfully
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
