import logo from "./images/blue-bike-logo2.png";
import styles from "./Header.module.css";
export function HeaderPlain() {
  return (
    <header className={styles.header}>
      <div className={styles.logoContainer}>
        <a href="/">
          {" "}
          <img
            src={logo}
            className="logo_img"
            alt="logo"
            style={{ width: "150px", height: "50px" }}
          />
        </a>
      </div>
    </header>
  );
}
