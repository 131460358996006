import React, { useState } from "react";
import ModerateReview from "./ModerateReview";
import AggregateWorkshopRating from "./AggregateWorkshopRating";
import styles from "./Review.module.css";
import { useNavigate } from "react-router-dom";

export default function ReviewActions() {
  const navigate = useNavigate();
  const handleClick = (page) => {
    navigate("/" + page);
  };

  return (
    <div>
      <div className={styles.bikeServiceContainer}>
        <h1 className={styles.header}>Review Actions</h1>
        <div>
          <button
            className={styles.actionBtn}
            onClick={handleClick.bind(this, "moderateReview")}
          >
            Moderate Review
          </button>
        </div>
        <div>
          <button
            className={styles.actionBtn}
            onClick={handleClick.bind(this, "aggregateWorkshopRating")}
          >
            Aggregate Rating
          </button>
        </div>
      </div>
    </div>
  );
}
