import styles from "./StatusUpdate.module.css";
import mainStyles from "../Main.module.css";
import { useNavigate } from "react-router-dom";

export default function StatusUpdate() {
  const navigate = useNavigate();

  const handleClick = (page) => {
    navigate("/" + page);
  };

  return (
    <div>
      <div>
        <h1 className={mainStyles.header}>Bike Servicing</h1>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "bikeServiceBookingList")}
        >
          Bike Service - Booking List
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "bikeServicePickUp")}
        >
          Bike Service - Pickup
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "bikeServiceDrop")}
        >
          Bike Service - Dropped at Workshop
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "bikeServiceComplete")}
        >
          Bike Service - Complete
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "bikeServiceCancel")}
        >
          Bike Service - Cancelled
        </div>
      </div>
      <div>
        <h1 className={mainStyles.header}>Car Wash</h1>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "carWashBookingList")}
        >
          Car Wash - Booking List
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "carWashStart")}
        >
          Car Wash - Start
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "carWashComplete")}
        >
          Car Wash - Complete
        </div>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "carWashCancel")}
        >
          Car Wash - Cancel
        </div>
      </div>
      <div>
        <h1 className={mainStyles.header}>New Bike</h1>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "newBikeDisplayStatusUpdate")}
        >
          New Bike - Display Status
        </div>
      </div>
      <div>
        <h1 className={mainStyles.header}>Used Bike</h1>
        <div
          className={styles.linkContainer}
          onClick={handleClick.bind(this, "usedBikeDisplayStatusUpdate")}
        >
          Used Bike - Display Status
        </div>
      </div>
    </div>
  );
}
