import React, { useState } from "react";
import styles from "./ContactForm.module.css"; // Import CSS module

import { Footer } from "../Footer/Footer";
import { HeaderPlain } from "../Header/HeaderPlain";
import axios from "axios";
import { API_HOST_URL } from "../../config/apiConfig";
import mainStyles from "../Main.module.css";

const ContactForm = () => {
  const [formData, setFormData] = useState({
    name: "",
    email: "",
    message: "",
  });
  const [message, setMessage] = useState();
  const [error, setError] = useState();

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Form submitted:", formData);
    // Perform form submission logic here
    axios
      .post(API_HOST_URL + "/contactUs/", formData)
      .then((response) => {
        setMessage(response.data.message);
      })
      .catch((error) => {
        setError(error.message);
      });
  };

  return (
    <div>
      <div className={styles.formContainer}>
        <h2>Contact Us</h2>
        {!message && (
          <div>
            <form onSubmit={handleSubmit}>
              <div className={styles.formGroup}>
                <label htmlFor="name">Name</label>
                <input
                  type="text"
                  name="name"
                  id="name"
                  value={formData.name}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="email">Email</label>
                <input
                  type="email"
                  name="email"
                  id="email"
                  value={formData.email}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className={styles.formGroup}>
                <label htmlFor="message">Message</label>
                <textarea
                  name="message"
                  id="message"
                  value={formData.message}
                  onChange={handleChange}
                  required
                />
              </div>
              <button type="submit" className={styles.submitButton}>
                Send Message
              </button>
            </form>
          </div>
        )}
        {message && <div className={mainStyles.successMessage}>{message}</div>}
        {error && <div className={mainStyles.errorMessage}>{error}</div>}
      </div>
      <Footer />
    </div>
  );
};

export default ContactForm;
