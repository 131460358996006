import { useEffect, useState } from "react";
import styles from "./booking.module.css";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";
import { useNavigate } from "react-router-dom";
import { Button, TextField } from "@mui/material";
import AppPagination from "../Pagination/AppPagination";
import SearchIcon from "@mui/icons-material/Search";

export default function BikeServiceBookingList() {
  const [bikeServiceBookings, setBikeServiceBookings] = useState([]);
  const navigate = useNavigate();
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bookingId, setBookingId] = useState("");

  useEffect(() => {
    api
      .get(
        "/api/bookings/bikeService?&page=" + page + "&bookingId=" + bookingId
      )
      .then((res) => {
        setBikeServiceBookings(res.data.bikeServices);
        setTotalPages(res.data.totalPages);
      });
  }, [page, bookingId]);

  const showBikeServicingPage = (bookingId) => {
    navigate("/bikeServicing?bookingId=" + bookingId);
  };

  const showBikeServiceBookingDetailPage = (bookingId) => {
    navigate("/bikeServiceBookingDetail?bookingId=" + bookingId);
  };

  return (
    <div>
      <div>
        <header className={mainStyles.header}>Bike Service Booking List</header>
        {/* <div className={styles.headerRow}>
          <div className={styles.fieldHeader}>Booking #</div>
          <div className={styles.fieldHeader}>Customer Name</div>
          <div className={styles.fieldHeader}>Model</div>
          <div className={`${styles.fieldHeader} ${styles.serviceDateHeader}`}>
            Service Date
          </div>
          <div className={styles.fieldHeader}>Reg. Number</div>
          <div className={styles.fieldHeader}>Status</div>
        </div> */}
        <div className={styles.itemsRoot}>
          <div className={styles.searchBox}>
            <div>
              <TextField
                id="bookingId"
                label="Booking Id"
                variant="outlined"
                sx={{ borderRadius: "20px" }}
                onBlur={(e) => {
                  setBookingId(e.target.value);
                }}
              />
            </div>
            <div className={styles.searchIcon}>
              <SearchIcon />
            </div>
          </div>
          <div className={styles.itemList}>
            {bikeServiceBookings.map((booking, index) => {
              return (
                <div key={index} className={styles.serviceItem}>
                  <div className={styles.fieldName}>Booking #</div>
                  <div className={styles.fieldValue}>
                    {booking.bookingNumber}
                  </div>
                  <div className={styles.fieldName}>Customer Name</div>
                  <div className={styles.fieldValue}>
                    {booking.firstName} {booking.lastName}
                  </div>
                  <div className={styles.fieldName}>Model</div>
                  <div className={styles.fieldValue}>
                    {booking.brand} {booking.model}
                  </div>
                  {/* <div className={`${styles.field} ${styles.serviceDate}`}>
                  {booking.scheduledAt}
                </div>
                <div className={styles.field}>{booking.registrationNumber}</div> */}
                  <div className={styles.fieldName}>Status</div>
                  <div className={styles.fieldValue}>BOOKED</div>
                  <div className={styles.serviceButtonContainer}>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) =>
                        showBikeServiceBookingDetailPage(booking.bookingId)
                      }
                    >
                      View
                    </Button>
                    <Button
                      variant="contained"
                      color="primary"
                      size="small"
                      onClick={(e) => showBikeServicingPage(booking.bookingId)}
                    >
                      Create Service
                    </Button>
                  </div>
                </div>
              );
            })}
            <AppPagination setPage={setPage} totalPages={totalPages} />
          </div>
        </div>
      </div>
    </div>
  );
}
