import { useEffect, useState } from "react";
import styles from "./Worker.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import Divider from "@mui/material/Divider";
import Chip from "@mui/material/Chip";
import validator from "validator";
import { API_HOST_URL, api } from "../../config/apiConfig";
import mainStyles from "../Main.module.css";
import loadingImage from "../loading.gif";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import axios from "axios";

export default function Worker() {
  const [isSaved, setIsSaved] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");
  const [isLoading, setIsLoading] = useState(false);

  //States for form fields
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [dateOfBirth, setDateOfBirth] = useState("");
  const [dateOfJoining, setDateOfJoining] = useState("");
  const [dateOfLeaving, setDateOfLeaving] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [alternatePhoneNumber, setAlternatePhoneNumber] = useState("");
  const [houseNumber, setHouseNumber] = useState("");
  const [street, setStreet] = useState("");
  const [cityId, setCityId] = useState("");
  const [pinCode, setPinCode] = useState("");

  //Validators state
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [isValidAlternatePhone, setIsValidAlternatePhone] = useState(true);
  const [hasError, setHasError] = useState(false);

  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const token = localStorage.getItem("token");

  const handleClickOpen = () => {
    setOtpError(false);
    if (otpVerified) return;
    setOpen(true);
    axios
      .get(API_HOST_URL + "/otp/email?email=" + email)
      .then((res) => {
        // setCountries(res);
      })
      .catch((error) => {
        alert("Error in sending OTP");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    const otpObj = { email, otp };

    axios
      .post(API_HOST_URL + "/otp/email/verify", otpObj)
      .then((res) => {
        if (res) {
          if (res.data.success) {
            setOtpVerified(true);
            setOpen(false);
            setOtpError(false);
          } else {
            setOtpError(true);
          }
        } else {
          setOtpError(true);
        }
      })
      .catch((error) => {
        setOtpError(true);
      });
  };

  useEffect(() => {
    axios.get(API_HOST_URL + "/countries/").then((res) => {
      setCountries(res.data);
    });
  }, []);

  const loadStates = (e, countryId) => {
    axios.get(API_HOST_URL + "/states/" + countryId).then((res) => {
      setCountryId(countryId);
      setStates(res.data);
    });
  };

  const loadCities = (e, stateId) => {
    axios.get(API_HOST_URL + "/cities/" + stateId).then((res) => {
      setStateId(stateId);
      setCities(res.data);
    });
  };

  // Email Validation
  const validateEmail = (e) => {
    setEmail(e.target.value);

    if (validator.isEmail(email)) {
      setIsValidEmail(true);
    } else {
      setIsValidEmail(false);
    }
  };

  // Phone Number Validation
  const validatePhone = (e) => {
    setPhoneNumber(e.target.value);

    if (validator.isMobilePhone(phoneNumber)) {
      setIsValidPhone(true);
    } else {
      setIsValidPhone(false);
    }
  };

  const validateAlternatePhone = (e) => {
    setAlternatePhoneNumber(e.target.value);

    if (validator.isMobilePhone(alternatePhoneNumber)) {
      setIsValidAlternatePhone(true);
    } else {
      setIsValidAlternatePhone(false);
    }
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setFirstName("");
    setLastName("");
    setDateOfBirth("");
    setDateOfJoining("");
    setDateOfLeaving("");
    setEmail("");
    setPhoneNumber("");
    setAlternatePhoneNumber("");
    setHouseNumber("");
    setStreet("");
    setCityId("");
    setPinCode("");
    setIsValidEmail(true);
    setIsValidPhone(true);
    setIsValidAlternatePhone(true);
    setHasError(false);
    setIsSaved(false);
    setOtpVerified(false);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const customer = {
      firstName,
      lastName,
      dateOfBirth,
      dateOfJoining,
      dateOfLeaving,
      email,
      phoneNumber,
      alternatePhoneNumber,
      houseNumber,
      street,
      cityId,
      stateId,
      countryId,
      pinCode,
    };

    if (
      firstName !== "" &&
      cityId !== "" &&
      pinCode !== "" &&
      isValidPhone &&
      isValidAlternatePhone &&
      isValidEmail &&
      otpVerified
    ) {
      api
        .post("/api/workers/", customer)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loading}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Plese enter the OTP received in your email id.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="otp"
            label="OTP"
            fullWidth
            variant="standard"
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={verifyOtp}>Verify</Button>
        </DialogActions>
        {otpError && (
          <div className={mainStyles.otpError}>
            Incorrect OTP, Please entter a correct OTP
          </div>
        )}
      </Dialog>
      <header className={mainStyles.header}>Add Worker</header>
      <form onSubmit={handleSubmit}>
        <Divider>
          <Chip label="Personal Information" size="small" />
        </Divider>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setFirstName(e.target.value)}
            label="Enter First Name"
          />
        </div>

        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onChange={(e) => setLastName(e.target.value)}
            label="Enter Last Name"
          />
        </div>
        <div className={styles.componentContainer}>
          <label className={styles.component} for="birthday">
            Date Of Birth{" "}
          </label>
          <input
            type="date"
            onBlur={(e) => setDateOfBirth(e.target.value)}
            id="birthday"
            name="birthday"
          />
        </div>
        <div className={styles.componentContainer}>
          <label className={styles.component} for="birthday">
            Date Of Joining{" "}
          </label>
          <input
            type="date"
            onBlur={(e) => setDateOfJoining(e.target.value)}
            id="birthday"
            name="birthday"
          />
        </div>
        <div className={styles.componentContainer}>
          <label className={styles.component} for="birthday">
            Date Of Leaving{" "}
          </label>
          <input
            type="date"
            onBlur={(e) => setDateOfLeaving(e.target.value)}
            id="birthday"
            name="birthday"
          />
        </div>
        <Divider>
          <Chip label="Contact Information" size="small" />
        </Divider>

        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            type="phone"
            variant="standard"
            onChange={(e) => validatePhone(e)}
            label="Enter Phone Number"
          />
        </div>
        <div className={styles.componentContainer}>
          {!isValidPhone && (
            <p className={styles.errorMessage}>
              Please enter a valid Phone number
            </p>
          )}
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            type="phone"
            variant="standard"
            onChange={(e) => validateAlternatePhone(e)}
            label="Enter alternate Phone Number"
          />
        </div>
        <div className={styles.componentContainer}>
          {!isValidAlternatePhone && (
            <p className={styles.errorMessage}>
              Please enter a valid alternate Phone number
            </p>
          )}
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onBlur={(e) => setHouseNumber(e.target.value)}
            label="Enter House Number"
          />
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onBlur={(e) => setStreet(e.target.value)}
            label="Enter Street"
          />
        </div>
        <div className={styles.componentContainer}>
          <div className={mainStyles.emailTxtDiv}>
            {" "}
            <TextField
              className={styles.component}
              type="email"
              variant="standard"
              onChange={(e) => validateEmail(e)}
              label="Enter Email Id"
            />
          </div>
          <div className={mainStyles.verifyBtnDiv}>
            {" "}
            <Button
              variant="outlined"
              onClick={handleClickOpen}
              className={
                otpVerified ? mainStyles.inactiveBtn : mainStyles.activeBtn
              }
            >
              Verify
            </Button>
          </div>
          <div className={mainStyles.cirlceIconDiv}>
            {otpVerified && <CheckCircleIcon />}
          </div>
        </div>
        <div className={styles.componentContainer}>
          {!isValidEmail && (
            <p className={styles.errorMessage}>Please enter a valid email id</p>
          )}
        </div>
        <div className={styles.componentContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => loadStates(e, e.target.value)}
          >
            <option> Select Country </option>
            {countries.map((country) => (
              <option key={country.id} value={country.id}>
                {country.countryName}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.componentContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => loadCities(e, e.target.value)}
          >
            <option> Select State </option>
            {states.map((state) => (
              <option key={state.stateId} value={state.stateId}>
                {state.stateName}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.componentContainer}>
          <select
            className={styles.ddl}
            onChange={(e) => setCityId(e.target.value)}
          >
            <option> Select City </option>
            {cities.map((city) => (
              <option key={city.cityId} value={city.cityId}>
                {city.cityName}
              </option>
            ))}
          </select>
        </div>
        <div className={styles.componentContainer}>
          <TextField
            className={styles.component}
            variant="standard"
            onBlur={(e) => setPinCode(e.target.value)}
            label="Enter Zip code"
          />
        </div>
        <div className={styles.componentContainer}>
          <Button
            className={styles.saveBtn}
            type="submit"
            variant="contained"
            startIcon={<SaveIcon />}
          >
            {" "}
            Save Wroker
          </Button>
        </div>

        <div className={styles.componentContainer}>
          {hasError && (
            <p className={mainStyles.error}>
              Please enter all the mandatory fields with correct value
            </p>
          )}

          {isSaved && (
            <div>
              <p className={mainStyles.success}>Wroker Saved Successfully</p>
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
