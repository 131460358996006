import { useState, useEffect } from "react";
import styles from "./booking.module.css";
import axios from "axios";
import { Button, TextField } from "@mui/material";
import validator from "validator";
import { API_HOST_URL, api } from "../../config/apiConfig";
import PhoneInTalkRoundedIcon from "@mui/icons-material/PhoneInTalkRounded";
import EmailRoundedIcon from "@mui/icons-material/EmailRounded";
import LocationOnIcon from "@mui/icons-material/LocationOn";
import BadgeIcon from "@mui/icons-material/Badge";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import Box from "@mui/material/Box";
import InputLabel from "@mui/material/InputLabel";
import MenuItem from "@mui/material/MenuItem";
import FormControl from "@mui/material/FormControl";
import Select from "@mui/material/Select";
import ArrowForwardIcon from "@mui/icons-material/ArrowForward";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { useNavigate } from "react-router-dom";

export default function CarWashBooking() {
  const [brands, setBrands] = useState([]);
  const [colors, setColors] = useState([]);
  const [cities, setCities] = useState([]);
  const [models, setModels] = useState([]);
  const [carModelId, setCarModelId] = useState();
  const [brandId, setBrandId] = useState();
  const [scheduledAt, setScheduledAt] = useState(new Date());
  const [color, setColor] = useState();
  const [customerFullName, setCustomerFullName] = useState();
  const [email, setCustomerEmail] = useState();
  const [phoneNumber, setCustomerMobile] = useState();
  const [street, setCustomerStreet] = useState();
  const [cityId, setCustomerCityId] = useState();
  const [hasError, setHasError] = useState(false);
  const [isSaved, setIsSaved] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialData = () => {
    const getAllColors = axios.get(API_HOST_URL + "/colors/");
    const getAllBrands = api.get("/api/brands/");
    const getAllCities = axios.get(API_HOST_URL + "/cities/");

    axios.all([getAllColors, getAllBrands, getAllCities]).then(
      axios.spread((...allData) => {
        setColors(allData[0].data);
        setBrands(allData[1].data);
        setCities(allData[2].data);
        setScheduledAt(dayjs(new Date()));
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const loadModels = (e, brandId) => {
    api.get("/api/carModels/" + brandId).then((res) => {
      setBrandId(brandId);
      setModels(res.data);
    });
  };

  // Email Validation
  const isValidEmail = () => {
    return email.match(/^\S+@\S+\.\S+$/);
  };

  // Phone Number Validation
  const isValidPhone = () => {
    return phoneNumber.match(/\d/g).length === 10;
  };

  // eslint-disable-next-line no-extend-native
  Date.prototype.addDays = function (days) {
    var date = new Date(this.valueOf());
    date.setDate(date.getDate() + days);
    return date;
  };

  const validateForm = () => {
    if (
      isValidEmail() &&
      isValidPhone() &&
      carModelId !== undefined &&
      brandId !== undefined &&
      scheduledAt !== undefined &&
      color !== undefined &&
      customerFullName !== undefined &&
      street !== undefined &&
      cityId !== undefined
    )
      return true;

    return false;
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setCarModelId("");
    setBrandId("");
    setScheduledAt("");
    setColor("");
    setCustomerFullName("");
    setCustomerEmail("");
    setCustomerMobile("");
    setCustomerStreet("");
    setCustomerCityId("");
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      let customerId = localStorage.getItem("userId");
      let customerFirstName = customerFullName.split(" ")[0];
      let customerLastName = customerFullName.split(" ")[1];

      const booking = {
        customerId,
        carModelId,
        brandId,
        scheduledAt,
        color,
        customerFirstName,
        customerLastName,
        email,
        phoneNumber,
        street,
        cityId,
      };

      api
        .post("/api/bookings/carWash", booking)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div className={styles.rootContainer}>
      <form onSubmit={handleSubmit}>
        <header className={mainStyles.header}>Car Wash Booking</header>

        <div className={styles.ddlContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="brandSelect">Brand</InputLabel>
              <Select
                labelId="brandSelect"
                id="brandSelect"
                // value={age}
                label="brand"
                onChange={(e) => loadModels(e, e.target.value)}
                className={styles.ddl}
                defaultValue=""
              >
                {brands.map((brand) => (
                  <MenuItem key={brand.id} value={brand.brandId}>
                    {brand.brandName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        {/* <div className={styles.ddlContainer}>

                    <select className={styles.ddl} onChange={(e) => loadModels(e, e.target.value)} >
                        <option> Select Brand </option>
                        {brands.map((brand) => (
                            <option key={brand.id} value={brand.brandId}>{brand.brandName}</option>
                        ))}

                    </select>
                </div> */}
        <div className={styles.ddlContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="modelSelect">Model</InputLabel>
              <Select
                labelId="modelSelect"
                id="modelSelect"
                // value={age}
                label="Model"
                onChange={(e) => setCarModelId(e.target.value)}
                className={styles.ddl}
                defaultValue=""
              >
                {models.map((model) => (
                  <MenuItem key={model.id} value={model.id}>
                    {model.name}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <div className={styles.ddlContainer}>
                    <select className={styles.ddl} onChange={(e) => setCarModelId(e.target.value)} >
                        <option> Select Model </option>
                        {models.map((model) => (
                            <option key={model.id} value={model.id}>{model.name}</option>
                        ))}

                    </select>
                </div> */}
        <div className={styles.ddlContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="colorSelect">Color</InputLabel>
              <Select
                labelId="colorSelect"
                id="colorSelect"
                // value={age}
                label="Color"
                onChange={(e) => setColor(e.target.value)}
                className={styles.ddl}
                defaultValue=""
              >
                {colors.map((color) => (
                  <MenuItem key={color} value={color}>
                    {color}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>
        {/* <div className={styles.ddlContainer}>
                    <select className={styles.ddl} onChange={(e) => setColor(e.target.value)} >
                        <option> Select Color </option>
                        {colors.map((color) => (
                            <option key={color} value={color}>{color}</option>
                        ))}

                    </select>
                </div> */}

        <div className={styles.dualComponentContainer}>
          <div className={styles.iconDiv}>
            <BadgeIcon className={styles.icon} fontSize="large" />
          </div>
          <div className={styles.textDiv}>
            <TextField
              className={styles.txtBox}
              variant="standard"
              onBlur={(e) => setCustomerFullName(e.target.value)}
              label="Full Name"
            />
          </div>
        </div>

        <div className={styles.dualComponentContainer}>
          <div className={styles.iconDiv}>
            <PhoneInTalkRoundedIcon className={styles.icon} fontSize="large" />
          </div>
          <div className={styles.textDiv}>
            <TextField
              className={styles.txtBox}
              type="mobile"
              variant="standard"
              onBlur={(e) => setCustomerMobile(e.target.value)}
              label="Contact No"
            />
          </div>
        </div>
        <div className={styles.dualComponentContainer}>
          <div className={styles.iconDiv}>
            <EmailRoundedIcon className={styles.icon} fontSize="large" />
          </div>
          <div className={styles.textDiv}>
            <TextField
              className={styles.txtBox}
              type="email"
              variant="standard"
              onBlur={(e) => setCustomerEmail(e.target.value)}
              label="Email"
            />
          </div>
        </div>
        <div className={styles.dualComponentContainer}>
          <div className={styles.iconDiv}>
            <LocationOnIcon className={styles.icon} fontSize="large" />
          </div>
          <div className={styles.textDiv}>
            <TextField
              className={styles.txtBox}
              multiline
              rows={3}
              onBlur={(e) => setCustomerStreet(e.target.value)}
              label="Address"
            />
          </div>
        </div>

        {/* <div className={styles.ddlContainer}>

                    <select className={styles.ddl} onChange={(e) => setCustomerCityId(e.target.value)} >
                        <option> Select City </option>
                        {cities.map((city) => (
                            <option key={city.cityId} value={city.cityId}>{city.cityName}</option>
                        ))}

                    </select>

                </div> */}

        <div className={styles.ddlContainer}>
          <Box sx={{ minWidth: 120 }}>
            <FormControl fullWidth>
              <InputLabel id="demo-simple-select-label">City</InputLabel>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                // value={age}
                label="Age"
                onChange={(e) => setCustomerCityId(e.target.value)}
                className={styles.ddl}
                defaultValue=""
              >
                <MenuItem key="default"> Select City </MenuItem>
                {cities.map((city) => (
                  <MenuItem key={city.cityId} value={city.cityId}>
                    {city.cityName}
                  </MenuItem>
                ))}
              </Select>
            </FormControl>
          </Box>
        </div>

        {/* <div className={styles.componentContainer}  >
                    <div className={styles.scheduleDate} >

                        <input type="date" onBlur={(e) => setScheduleDate(e.target.value)} id="scheduleAt" name="scheduleAt" />
                    </div>

                    <div className={styles.scheduleTime} >

                        <input type="time" onBlur={(e) => setScheduleTime(e.target.value)} id="scheduleAtTime" name="scheduleAtTime" />
                    </div>

                </div> */}

        <div className={styles.ddlContainer}>
          <LocalizationProvider dateAdapter={AdapterDayjs}>
            <DemoContainer components={["MobileDateTimePicker"]}>
              <DemoItem label="Schedule Date/Time">
                <MobileDateTimePicker
                  className={styles.dateTime}
                  onChange={(e) => setScheduledAt(e)}
                  defaultValue={dayjs(new Date())}
                />
              </DemoItem>
            </DemoContainer>
          </LocalizationProvider>
        </div>

        <Button
          type="submit"
          variant="contained"
          endIcon={<ArrowForwardIcon />}
        >
          {" "}
          Book Now
        </Button>
        <div className={styles.componentContainer}>
          {hasError && (
            <p className={mainStyles.error}>
              Please enter all the mandatory fields with correct value
            </p>
          )}

          {isSaved && (
            <p className={mainStyles.success}>
              Bike service booking completed.
            </p>
          )}
        </div>
      </form>
    </div>
  );
}
