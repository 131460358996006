import React, { useEffect, useState } from "react";
import { API_HOST_URL, api } from "../../config/apiConfig";
import mainStyles from "../Main.module.css";
import styles from "./Profile.module.css";
import validator from "validator";
import { BIKE_DISPLAY_STATUS } from "../Constant/Constant";

import {
  Box,
  Button,
  FormControl,
  InputLabel,
  Select,
  TextField,
  MenuItem,
} from "@mui/material";
import { useNavigate } from "react-router-dom";
import axios from "axios";

const UpdateProfile = () => {
  const [profile, setProfile] = useState();
  const navigate = useNavigate();
  const [cities, setCities] = useState([]);

  // axios.all([getAllCities]).then(
  //   axios.spread((...allData) => {
  //     setCities(allData[0].data);
  //   })
  // );

  useEffect(() => {
    api.get("/api/profile").then((res) => {
      setProfile(res.data);
    });

    axios.get(`${API_HOST_URL}/cities/`).then((res) => {
      setCities(res.data);
    });
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    const data = new FormData(e.target);
    const formData = {
      email: profile.email,
      name: data.get("name"),
      phoneNo: data.get("phoneNo"),
      address: data.get("address"),
      cityId: data.get("cityId"),
      pincode: data.get("pincode"),
      password: data.get("password"),
      confirmPassword: data.get("confirmPassword"),
    };

    if (validateForm(formData)) {
      api
        .put("/api/profile", formData)
        .then((res) => {
          alert(res.data.message);
          navigate("/profile");
        })
        .catch((err) => {
          alert(err.response.data);
        });
    } else {
      alert("Please enter valid data");
    }
  };

  const validateForm = (formData) => {
    if (
      matchPassword(formData.password, formData.confirmPassword) &&
      validatePhone(formData.phoneNo)
    ) {
      return true;
    }
    return false;
  };

  const matchPassword = (password, confirmPassword) => {
    if (password === confirmPassword) {
      if (password.length === 0 || validatePassword(password)) return true;
    }
    return false;
  };

  const validatePassword = (password) => {
    let isValid = true;
    if (!password.match(/[a-z]+/)) {
      isValid = false;
    }
    if (!password.match(/[A-Z]+/)) {
      isValid = false;
    }
    if (!password.match(/[0-9]+/)) {
      isValid = false;
    }
    if (password.length < 4) {
      isValid = false;
    }
    return isValid;
  };

  const validatePhone = (phone) => {
    return phone.match(/^\d{10}$/);
  };

  return (
    <div>
      <div className={mainStyles.header}>Update Profile</div>
      <form onSubmit={handleSubmit}>
        {profile && (
          <div className={styles.fieldContainer}>
            <div>
              <TextField
                className={styles.component}
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="name"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Name"
                defaultValue={profile.name}
              />
            </div>
            <div>
              <TextField
                className={styles.component}
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="password"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Password"
              />
            </div>
            <div>
              <TextField
                className={styles.component}
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="confirmPassword"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Confirm Password"
              />
            </div>

            <div>
              <TextField
                className={styles.component}
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="phoneNo"
                type="phone"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Phone"
                defaultValue={profile.phoneNo}
              />
            </div>

            <div>
              <TextField
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="address"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Address"
                defaultValue={profile.address}
              />
            </div>
            <div>
              {" "}
              <Box sx={{ width: 1 / 1, padding: "10px" }}>
                <FormControl fullWidth>
                  <InputLabel id="brandSelect">City</InputLabel>
                  <Select
                    labelId="citySelect"
                    id="citySelect"
                    // value={age}
                    name="cityId"
                    // onChange={(e) => loadModels(e, e.target.value)}
                    className={styles.ddl}
                    defaultValue={profile?.cityId}
                  >
                    {cities.map((city) => (
                      <MenuItem key={city.cityId} value={city.cityId}>
                        {city.cityName}
                      </MenuItem>
                    ))}
                  </Select>
                </FormControl>
              </Box>
            </div>

            <div>
              <TextField
                className={styles.component}
                sx={{ width: 1 / 1, padding: "10px" }}
                variant="standard"
                name="pincode"
                // onChange={(e) => setMileage(e.target.value)}
                label="Enter Pincode"
                defaultValue={profile.pincode}
              />
            </div>
            <div className={styles.center}>
              <Button
                variant="conatined"
                color="primary"
                type="submit"
                className={mainStyles.submitButton}
                onClick={(e) => {
                  navigate("/updateProfile");
                }}
              >
                Update
              </Button>
            </div>
          </div>
        )}
      </form>
    </div>
  );
};

export default UpdateProfile;
