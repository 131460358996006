import { useEffect, useState } from "react";
import styles from "./NewBike.module.css";
import { Footer } from "../Footer/Footer";
import mainStyles from "../Main.module.css";
import bikesImage from "./images/bikes.png";
import AppPagination from "../Pagination/AppPagination";
import {
  Box,
  Button,
  InputLabel,
  MenuItem,
  Select,
  FormControl,
} from "@mui/material";
import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import { api } from "../../config/apiConfig";
import { BIKE_DISPLAY_STATUS } from "../Constant/Constant";
import { StyleSharp } from "@mui/icons-material";

export default function NewBikeDisplayStatusUpdate() {
  const [bikes, setBikes] = useState([]);
  const [bikeId, setBikeId] = useState("");
  const [totalPages, setTotalPages] = useState(0);
  const [page, setPage] = useState(0);
  const [bikeNames, setBikeNames] = useState([]);
  // const [model, setModel] = useState("");
  const [open, setOpen] = useState(false);
  const [status, setStatus] = useState("PENDING");

  const openConfirmationDialogBox = (e, bikeId) => {
    setBikeId(bikeId);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  let role = localStorage.getItem("role");

  useEffect(() => {
    api.get("/api/bikeModels/names").then((res) => {
      setBikeNames(res.data.bikeModelNames);
    });
    loadBikes();
  }, [page, status]);

  const loadBikes = () => {
    api.get("/api/newBikes/status/" + status + "?page=" + page).then((res) => {
      setBikes(res.data.bikes);
      setTotalPages(res.data.totalPages);
    });
  };

  const handleStatusChange = (status) => {
    setStatus(status);
  };

  const handleDelete = () => {
    api
      .put("/api/newBikes/" + bikeId + "/delete")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });

    setOpen(false);
  };

  const handleApprove = (e, bikeId) => {
    api
      .put("/api/newBikes/" + bikeId + "/approve")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  const handleReject = (e, bikeId) => {
    api
      .put("/api/newBikes/" + bikeId + "/reject")
      .then((res) => {
        loadBikes();
        window.scroll(0, 0);
      })
      .catch((err) => {
        console.log(err);
      });
  };

  return (
    <div>
      <div>
        <div className={styles.headingImageDiv}>
          <img src={bikesImage} alt="Bikes" className={styles.bigImage} />
        </div>
        <div className={styles.bikeListcontainer}>
          <div className={mainStyles.header}>New Bikes</div>
          <div className={styles.center}>
            {" "}
            <Box sx={{ padding: "10px" }}>
              <FormControl fullWidth>
                <InputLabel id="brandSelect">Status</InputLabel>
                <Select
                  labelId="statusSelect"
                  id="statusSelect"
                  name="status"
                  className={styles.ddl}
                  defaultValue={status}
                  onChange={(e) => handleStatusChange(e.target.value)}
                >
                  {BIKE_DISPLAY_STATUS.map((status) => (
                    <MenuItem key={status} value={status}>
                      {status}
                    </MenuItem>
                  ))}
                </Select>
              </FormControl>
            </Box>
          </div>

          {bikes.map((bike) => (
            <div>
              <div className={styles.propContainer}>
                <div className={styles.bikeDetailContainer}>
                  <div className={styles.bikeImageDiv}>
                    <img
                      className={styles.bikeImage}
                      src={bike.imageUrl}
                      alt="bikeImage"
                    />
                  </div>
                  <div className={styles.bikeDetailDiv}>
                    <div className={styles.bikeDetail}>
                      <div className={styles.brand}>
                        {bike.brand} {bike.model}
                      </div>
                      <div className={styles.modelYear}>
                        {bike.engineCapacity}{" "}
                      </div>
                      <div className={styles.price}>
                        {" "}
                        ₹{bike.exShowroomPrice}/-
                      </div>
                    </div>
                  </div>
                </div>
              </div>

              <div className={styles.linkContainer}>
                {bike.displayStatus !== "DELETED" &&
                  bike.displayStatus !== "APPROVED" && (
                    <div>
                      <Button
                        onClick={(e) => handleApprove(e, bike.bikeId)}
                        className={styles.button}
                      >
                        Approve
                      </Button>
                    </div>
                  )}
                {bike.displayStatus !== "DELETED" &&
                  bike.displayStatus !== "REJECTED" && (
                    <div>
                      <Button
                        onClick={(e) => handleReject(e, bike.bikeId)}
                        className={styles.button}
                      >
                        Reject
                      </Button>
                    </div>
                  )}
                {bike.displayStatus !== "DELETED" && (
                  <div>
                    <Button
                      onClick={(e) => openConfirmationDialogBox(e, bike.bikeId)}
                      className={`${styles.button} ${styles.delete}`}
                    >
                      Delete
                    </Button>
                  </div>
                )}
              </div>
            </div>
          ))}
          <AppPagination setPage={setPage} totalPages={totalPages} />
          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="alert-dialog-title"
            aria-describedby="alert-dialog-description"
          >
            <DialogTitle id="alert-dialog-title">{"Delete Bike?"}</DialogTitle>
            <DialogContent>
              <DialogContentText>
                Are you sure you want to delete this bike?
              </DialogContentText>
            </DialogContent>
            <DialogActions>
              <Button autoFocus onClick={handleClose}>
                Cancel
              </Button>
              <Button
                onClick={handleDelete}
                autoFocus
                className={`${styles.button} ${styles.delete}`}
              >
                Delete
              </Button>
            </DialogActions>
          </Dialog>
        </div>

        <Footer />
      </div>
    </div>
  );
}
