import { useEffect, useState } from "react";
import styles from "./customer.module.css";
import { Button, TextField } from "@mui/material";
import SaveIcon from "@mui/icons-material/Save";
import validator from "validator";
import { useNavigate } from "react-router-dom";
import { API_HOST_URL } from "../../config/apiConfig";
import loadingImgae from "../loading.gif";
import mainStyles from "../Main.module.css";

import Dialog from "@mui/material/Dialog";
import DialogActions from "@mui/material/DialogActions";
import DialogContent from "@mui/material/DialogContent";
import DialogContentText from "@mui/material/DialogContentText";
import DialogTitle from "@mui/material/DialogTitle";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";

import RemoveRedEyeIcon from "@mui/icons-material/RemoveRedEye";
import VisibilityOffIcon from "@mui/icons-material/VisibilityOff";
import axios from "axios";

export default function Customer() {
  const [isSaved, setIsSaved] = useState(false);

  const [states, setStates] = useState([]);
  const [cities, setCities] = useState([]);
  const [countries, setCountries] = useState([]);
  const [stateId, setStateId] = useState("");
  const [countryId, setCountryId] = useState("");

  //States for form fields
  const [firstName, setFirstName] = useState("");
  const [password, setPassword] = useState("");
  const [confirmPassword, setConfirmPassword] = useState("");
  const [email, setEmail] = useState("");
  const [phoneNumber, setPhoneNumber] = useState("");
  const [cityId, setCityId] = useState("");
  const [zipCode, setZipCode] = useState("");

  //Validators state
  const [isValidConfirmPassword, setIsValidConfirmPassword] = useState(false);
  const [isValidPassword, setIsValidPassword] = useState(false);
  const [isValidEmail, setIsValidEmail] = useState(true);
  const [isValidPhone, setIsValidPhone] = useState(true);
  const [hasError, setHasError] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const [showPassword, setShowPassword] = useState(false);
  const [showConfirmPassword, setShowConfirmPassword] = useState(false);

  const navigate = useNavigate();

  const token = localStorage.getItem("token");

  const [open, setOpen] = useState(false);
  const [otp, setOtp] = useState("");
  const [otpVerified, setOtpVerified] = useState(false);
  const [otpError, setOtpError] = useState(false);

  const handleClickOpen = () => {
    setOtpError(false);
    if (otpVerified) return;
    setOpen(true);
    axios
      .get(API_HOST_URL + "/otp/email?email=" + email)
      .then((res) => {
        // setCountries(res);
      })
      .catch((error) => {
        alert("Error in sending OTP");
      });
  };

  const handleClose = () => {
    setOpen(false);
  };

  const verifyOtp = (e) => {
    e.preventDefault();
    const otpObj = { email, otp };

    axios
      .post(API_HOST_URL + "/otp/email/verify", otpObj)
      .then((res) => {
        if (res) {
          if (res.data.success) {
            setOtpVerified(true);
            setOpen(false);
            setOtpError(false);
          } else {
            setOtpError(true);
          }
        } else {
          setOtpError(true);
        }
      })
      .catch((error) => {
        setOtpError(true);
      });
  };

  const headerList = {
    "Content-Type": "application/json",
    Authorization: "Bearer " + token,
  };
  useEffect(() => {
    axios
      .get(API_HOST_URL + "/cities/")
      .then((res) => {
        setCities(res.data);
      })
      .catch((error) => {
        console.log(error);
      });
  }, []);

  const matchPassword = (e) => {
    if (password === confirmPassword) {
      setIsValidConfirmPassword(true);
    } else {
      setIsValidConfirmPassword(false);
    }
  };

  const validatePassword = (e) => {
    let isValid = true;
    if (!password.match(/[a-z]+/)) {
      isValid = false;
    }
    if (!password.match(/[A-Z]+/)) {
      isValid = false;
    }
    if (!password.match(/[0-9]+/)) {
      isValid = false;
    }
    if (password.length < 4) {
      isValid = false;
    }

    setIsValidPassword(isValid);
  };

  // Email Validation
  const validateEmail = () => {
    return email.match(/^\S+@\S+\.\S+$/);
  };

  // Phone Number Validation
  const validatePhone = () => {
    return phoneNumber.match(/^\d{10}$/);
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);
    const customer = {
      firstName,
      password,
      email,
      phoneNumber,
      cityId,
    };

    if (
      validateEmail() &&
      isValidPassword &&
      isValidConfirmPassword &&
      validatePhone() &&
      otpVerified
    ) {
      axios
        .post(API_HOST_URL + "/register/customer/", customer)
        .then((res) => {
          onSuccessfulSave();
          navigate("/login");
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImgae} alt="loading" />
    </div>
  ) : (
    <div>
      <Dialog
        open={open}
        onClose={handleClose}
        PaperProps={{
          component: "form",
          onSubmit: (event) => {
            event.preventDefault();
            const formData = new FormData(event.currentTarget);
            const formJson = Object.fromEntries(formData.entries());
            const email = formJson.email;
            console.log(email);
            handleClose();
          },
        }}
      >
        <DialogTitle>Verify Email</DialogTitle>
        <DialogContent>
          <DialogContentText>
            Plese enter the OTP received in your email id.
          </DialogContentText>
          <TextField
            autoFocus
            required
            margin="dense"
            id="name"
            name="otp"
            label="OTP"
            fullWidth
            variant="standard"
            onChange={(e) => setOtp(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancel</Button>
          <Button onClick={verifyOtp}>Verify</Button>
        </DialogActions>
        {otpError && (
          <div className={mainStyles.otpError}>
            Incorrect OTP, Please entter a correct OTP
          </div>
        )}
      </Dialog>
      <div className={styles.container}>
        <div className={styles.loginRegister}>
          <a href="/login" className={styles.loginLink}>
            Log In
          </a>
        </div>
        <div className={styles.heading}>
          <div className={styles.heading1}>Chase Your Dream with</div>
          <div className={styles.heading2}>Blue Bike</div>
        </div>

        <div className={styles.formContainer}>
          <div>
            <form onSubmit={handleSubmit}>
              <div className={styles.customerForm}>
                <div className={styles.newReagistrationHeading}>
                  New Registration
                </div>
                <div className={styles.componentContainer}>
                  <TextField
                    className={styles.component}
                    variant="outlined"
                    onChange={(e) => setFirstName(e.target.value)}
                    label="Name"
                  />
                </div>
                <div className={styles.componentContainer}>
                  <TextField
                    className={styles.component}
                    type="phone"
                    variant="outlined"
                    onChange={(e) => setPhoneNumber(e.target.value)}
                    label="Contact"
                  />
                </div>
                <div className={styles.componentContainer}>
                  {!isValidPhone && (
                    <p className={styles.errorMessage}>
                      Please enter a valid Phone number
                    </p>
                  )}
                </div>

                <div className={styles.componentContainer}>
                  <div className={mainStyles.emailTxtDiv}>
                    {" "}
                    <TextField
                      className={styles.component}
                      type="email"
                      variant="standard"
                      onChange={(e) => setEmail(e.target.value)}
                      label="Enter Email Id"
                    />
                  </div>
                  <div className={mainStyles.verifyBtnDiv}>
                    {" "}
                    <Button
                      variant="outlined"
                      onClick={handleClickOpen}
                      className={
                        otpVerified
                          ? mainStyles.inactiveBtn
                          : mainStyles.activeBtn
                      }
                    >
                      Verify
                    </Button>
                  </div>
                  <div className={mainStyles.cirlceIconDiv}>
                    {otpVerified && <CheckCircleIcon />}
                  </div>
                </div>
                <div className={styles.passwordContainer}>
                  <TextField
                    className={styles.component}
                    variant="standard"
                    type={showPassword ? "text" : "password"}
                    onChange={(e) => setPassword(e.target.value)}
                    onBlur={(e) => validatePassword(e.target.value)}
                    label="Enter Password"
                  />
                  <div className={mainStyles.showPasswordIconDiv}>
                    {showPassword ? (
                      <RemoveRedEyeIcon
                        onClick={() => setShowPassword(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setShowPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.passwordContainer}>
                  <TextField
                    className={styles.component}
                    variant="standard"
                    type={showConfirmPassword ? "text" : "password"}
                    onChange={(e) => setConfirmPassword(e.target.value)}
                    onBlur={(e) => matchPassword(e.target.value)}
                    label="Confirm password"
                  />
                  <div className={mainStyles.showPasswordIconDiv}>
                    {showConfirmPassword ? (
                      <RemoveRedEyeIcon
                        onClick={() => setShowConfirmPassword(false)}
                      />
                    ) : (
                      <VisibilityOffIcon
                        onClick={() => setShowConfirmPassword(true)}
                      />
                    )}
                  </div>
                </div>
                <div className={styles.ddlContainer}>
                  <select
                    className={styles.ddl}
                    onChange={(e) => setCityId(e.target.value)}
                  >
                    <option> Select City </option>
                    {cities.map((city) => (
                      <option key={city.cityId} value={city.cityId}>
                        {city.cityName}
                      </option>
                    ))}
                  </select>
                </div>
              </div>

              <div>
                <Button
                  className={styles.saveBtn}
                  type="submit"
                  variant="contained"
                  startIcon={<SaveIcon />}
                >
                  {" "}
                  Register Now
                </Button>
              </div>
              <div className={styles.componentContainer}>
                {hasError && (
                  <p className={mainStyles.error}>
                    Password and Confirm Password are not same
                  </p>
                )}
                {hasError && (
                  <p className={mainStyles.error}>
                    Password should contain at least 6 characters with 1 small
                    letter, 1 capital letter, 1 number and 1 special character
                  </p>
                )}
                {hasError && (
                  <p className={mainStyles.error}>
                    Please enter all the mandatory fields with correct value
                  </p>
                )}

                {isSaved && (
                  <div>
                    <p className={mainStyles.success}>
                      Customer Saved Successfully
                    </p>
                  </div>
                )}
              </div>
            </form>
          </div>
        </div>
      </div>
    </div>
  );
}
