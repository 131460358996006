import React from "react";
import styles from "./AboutUs.module.css"; // Import the CSS module
import { Footer } from "../Footer/Footer";
import { HeaderPlain } from "../Header/HeaderPlain";

const AboutUs = () => {
  return (
    <div>
      <HeaderPlain />
      <div className={styles.servicesContainer}>
        <h1 className={styles.title}>Our Services</h1>
        <p className={styles.intro}>
          At <strong>Bluebike.in</strong>, we offer a range of services tailored
          to meet your biking and vehicle needs with convenience and quality:
        </p>

        <h2 className={styles.serviceHeading}>Car Wash</h2>
        <p className={styles.serviceDescription}>
          Keep your car looking fresh and clean with our professional car wash
          service. We provide thorough exterior and interior cleaning that
          ensures your vehicle sparkles like new.
        </p>

        <h2 className={styles.serviceHeading}>Bike Servicing</h2>
        <p className={styles.serviceDescription}>
          Regular maintenance is essential for optimal bike performance. Our
          expert technicians provide comprehensive bike servicing, ensuring a
          smooth and safe ride every time.
        </p>

        <h2 className={styles.serviceHeading}>Buy a Bike</h2>
        <p className={styles.serviceDescription}>
          Looking for a new or pre-owned bike? Explore our wide range of bikes
          available for purchase. Whether you're after a commuter bike or an
          adventure bike, we’ve got you covered.
        </p>

        <h2 className={styles.serviceHeading}>Sell a Bike</h2>
        <p className={styles.serviceDescription}>
          Ready to part ways with your bike? List it on Bluebike.in and get a
          fair price with our easy and hassle-free selling process.
        </p>

        <p className={styles.contact}>
          We’re committed to delivering exceptional service and ensuring that
          all your vehicle and biking needs are taken care of. Reach out to us
          at{" "}
          <a href="mailto:support@bluebike.in" className={styles.email}>
            support@bluebike.in
          </a>{" "}
          for any inquiries!
        </p>
      </div>
      <Footer />
    </div>
  );
};

export default AboutUs;
