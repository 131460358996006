import { useEffect, useState } from "react";
import styles from "./CarWash.module.css";
import { API_HOST_URL } from "../Constant/Constant";
import { Button, TextField } from "@mui/material";
import axios from "axios";
import SaveIcon from "@mui/icons-material/Save";
import dayjs from "dayjs";
import { DemoContainer, DemoItem } from "@mui/x-date-pickers/internals/demo";
import { MobileDateTimePicker } from "@mui/x-date-pickers/MobileDateTimePicker";
import { LocalizationProvider } from "@mui/x-date-pickers";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import loadingImage from "../loading.gif";
import mainStyles from "../Main.module.css";
import { api } from "../../config/apiConfig";

export default function CarWashComplete() {
  const [bookings, setBookings] = useState([]);
  const [carWash, setCarWash] = useState();
  const [isSaved, setIsSaved] = useState(false);
  const [hasError, setHasError] = useState(false);
  const [remarks, setRemarks] = useState();
  const [completedAt, setCompletedAt] = useState();
  const [bookingId, setBookingId] = useState();
  const [totalCost, setTotalCost] = useState(0.0);
  const [isLoading, setIsLoading] = useState(false);

  const fetchInitialData = () => {
    const getCarWashBookingIdList = api.get(
      "/api/bookings/bookingNumber/carWash"
    );

    axios.all([getCarWashBookingIdList]).then(
      axios.spread((...allData) => {
        setBookings(allData[0].data);
      })
    );
  };

  useEffect(() => {
    fetchInitialData();
  }, []);

  const validateForm = () => {
    if (
      remarks === "" ||
      completedAt === undefined ||
      completedAt === "" ||
      totalCost === 0.0
    ) {
      return false;
    }

    return true;
  };

  const loadCarWash = (id) => {
    setBookingId(id);
    api.get("/api/carWash/" + id).then((res) => {
      setCarWash(res.data);
      setRemarks(res.data.remarks);
      setCompletedAt(dayjs(new Date()));
      setTotalCost(res.data.estimatedCost);
    });
  };

  const onSuccessfulSave = () => {
    setIsSaved(true);
    setIsLoading(false);
    setHasError(false);
  };

  const onFailedSave = () => {
    setIsLoading(false);
    setIsSaved(false);
    setHasError(true);
  };

  const resetForm = () => {
    setBookingId();
    setRemarks("");
    setIsSaved(false);
    setTotalCost(0.0);
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    setIsLoading(true);

    if (validateForm()) {
      const carWash = {
        bookingId,
        completedAt,
        remarks,
        totalCost,
      };

      api
        .put("/api/carWash/complete", carWash)
        .then((res) => {
          onSuccessfulSave();
          resetForm();
        })
        .catch((error) => {
          onFailedSave();
        });
    } else {
      onFailedSave();
    }
  };

  return isLoading ? (
    <div className={mainStyles.loadingContainer}>
      <img src={loadingImage} alt="loading" />
    </div>
  ) : (
    <div>
      <form onSubmit={handleSubmit}>
        <div className={styles.carWashContainerDiv}>
          <header className={mainStyles.header}>Car Wash - Complete</header>
          {bookings && (
            <div className={styles.bookingDeailContainer}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Booking #</div>
                <div className={styles.bookingValue}>
                  <select
                    className={styles.ddl}
                    onChange={(e) => loadCarWash(e.target.value)}
                  >
                    <option> Select Booking Id </option>
                    {bookings.map((booking) => (
                      <option key={booking.bookingId} value={booking.bookingId}>
                        {booking.bookingNumber}
                      </option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
          )}

          {carWash && (
            <div className={styles.container}>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Name:</div>
                <div className={styles.bookingValue}>
                  {carWash.customerFullName}{" "}
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Model:</div>
                <div className={styles.bookingValue}>{carWash.carModel}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Color:</div>
                <div className={styles.bookingValue}>{carWash.color}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Total Cost:</div>
                <div className={styles.bookingValue}>
                  <TextField
                    onChange={(e) => setTotalCost(e.target.value)}
                    label="Enter cost"
                    defaultValue={carWash.estimatedCost}
                  />
                </div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Customer Address:</div>
                <div className={styles.bookingValue}>{carWash.address}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Servicing Status:</div>
                <div className={styles.bookingValue}>{carWash.status}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Worker:</div>
                <div className={styles.bookingValue}>{carWash.worker}</div>
              </div>
              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Remarks:</div>
                <div className={styles.bookingValue}>
                  <TextField
                    multiline
                    rows={2}
                    onBlur={(e) => setRemarks(e.target.value)}
                    label="Enter Remarks"
                    defaultValue={carWash.remarks}
                  />
                </div>
              </div>

              <div className={styles.bookingRow}>
                <div className={styles.bookingKey}>Completed At:</div>
                <div className={styles.bookingValue}>
                  <div className={styles.ddlContainer}>
                    <LocalizationProvider dateAdapter={AdapterDayjs}>
                      <DemoContainer components={["MobileDateTimePicker"]}>
                        <DemoItem label="Completed At">
                          <MobileDateTimePicker
                            className={styles.dateTime}
                            onChange={(e) => setCompletedAt(e)}
                            defaultValue={dayjs(new Date())}
                          />
                        </DemoItem>
                      </DemoContainer>
                    </LocalizationProvider>
                  </div>
                </div>
              </div>

              <div className={styles.component}>
                {" "}
                <Button
                  type="submit"
                  variant="contained"
                  endIcon={<SaveIcon />}
                >
                  {" "}
                  Update
                </Button>
              </div>

              {hasError && (
                <p className={mainStyles.error}>
                  Please fill all the mandatory fields.
                </p>
              )}
              {isSaved && (
                <p className={mainStyles.success}>
                  {" "}
                  Car Wash status updated successfully
                </p>
              )}
            </div>
          )}
        </div>
      </form>
    </div>
  );
}
