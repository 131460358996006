import React, { useEffect, useState } from "react";
import { HeaderPlain } from "./HeaderPlain";
import { Header } from "./Header";
import { useDispatch, useSelector } from "react-redux";
import { getUser } from "../../redux/state/Auth/Action";
import { useNavigate } from "react-router-dom";

const HeaderMain = () => {
  const jwt = localStorage.getItem("jwt");
  const dispatch = useDispatch();
  const { auth } = useSelector((store) => store);
  const navigate = useNavigate();
  useEffect(() => {
    if (jwt) {
      dispatch(getUser(jwt, navigate));
    }
  }, [jwt]);

  return (
    <div>
      {jwt && auth.user && <Header />}
      {/* {!jwt && <HeaderPlain />} */}
    </div>
  );
};

export default HeaderMain;
